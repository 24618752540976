module.exports = {
  common_msg:{
    success:'操作成功！',
    error:'操作失败，请稍后重试。'
  },
  trade_spot:{
    msgBuyPrice:'请输入正确的买入价！',
    msgBuyNum:'请输入正确的买入数量！',
    msgSellPrice:'请输入正确的卖出价！',
    msgSellNum:'请输入正确的卖出数量！',
    msgUstdNotEnough:'可用USDT不足！',
    msgSellNumNotEnough:'可卖出数量不足！',
  },
  download_text:{
    android:'安卓',
    iphone:'苹果',
  },

  language: {
    name: '简体中文'
  },
  login: {
    title: '欢迎登录',
    yhm: '用户名',
    username: '输入用户名',
    ma: '密码',
    pwd: '输入密码',
    zc: '注册',
    wj: '忘记密码',
    dl: '登录',
    cz: '重置密码后24小时内不允许提现',
    yxdz: '邮箱地址',
    srdz: '输入您的邮箱号码',
    yxyz: '邮箱验证',
    yzm: '验证码',
    send: '发送验证码',
    next: '下一步',
    czma: '重置密码',
    xmm: '新密码',
    szxmm: '设置新密码',
    qrma: '确认密码',
    zcqr: '再次输入新密码',
    qd: '确定',
    cj: '创建您的账号',
    sz: '设置密码',
    yyzh: '已有账号',
    qdl: '去登录',
  },
  nav: {
    sy: '首页',
    sc: '市场',
    bbjy: '币币交易',
    qhjy: '期货交易',
    xz: '下载',
    cb: '充币',
    tb: '提币',
    zc: '资产',
    da: '订单',
    dl: '登录',
    reg: '注册',
    out: '退出登录'
  },
  footer: {
    sq: '社区',
    zc: '支持',
    bzzx: '帮助中心',
    hysm: '合约说明',
    gywm: '关于我们',
    fvbz: '费率标准',
    sbsq: '上币申请',
    tk: '条款',
    flsm: '法律声明',
    fxts: '风险提示',
    yszc: '隐私政策',
    yhxy: '用户协议',
    lxwm: '联系我们',
    swhz: '商务合作',
    kfyx: '客服邮箱'
  },
  index: {
    qq: '全球',
    aq: '最大最安全',
    jys: '虚拟货币交易所',
    zc: '注册账号',
    rbb: '热币榜',
    xbb: '新币榜',
    zfb: '日涨幅榜',
    jyd: '交易对',
    jg: '价格',
    zdf: '涨跌幅',
    hq: '行情',
    xq: '详情',
    ckgd: '查看更多',
    xzwm: '为什么选择我们',
    x1: '安全与法制基金全方位保障',
    x2: '通过中心化和去中心化双重手段保障您的资产安全，并且是全球唯一一个投入数亿元资金为您的资产安全提供“安全与法制基金”的交易平台。',
    x3: '快捷的交易充提系统',
    x4: '经久的技术积淀,为您提供了高效便捷的实时交易系统。您的资金出入亦可秒充秒提。',
    x5: '一如既往 经久稳定',
    x6: '9年的稳定运营, 积累了众多行业领先的技术,继续为您提供稳定的服务,我们充满信心,并努力为之奋斗!',
    x7: '您可信赖的平台',
    x8: '作为全球历史最长和交易量最大的交易平台之一, 严格遵循行业规则,不参与市场运作。公开、透明、平等、合作一直是我们坚持的使命。',
    ys: '我们的优势',
    y1: '安全',
    y2: '行业领先安全技术和财务稳定性，确保您的资产安全有保障。',
    y3: '自由',
    y4: '可进行闪电式的开仓、平仓操作，更无分摊机制，用户交易盈利资金可100%自由支配。',
    y5: '快捷',
    y6: '高效上新优质且拥有绝佳流动性的币种项目。',
    cp: '我们的产品',
    c1: '现货交易',
    c2: '1000多种加密货币供您以最优价格进行交易',
    c3: '期货交易',
    c4: '期货交易是一种金融衍生品，它是相对于现货市场的交易',
    c5: '合约交易',
    c6: '合约交易是以现货的远期合约为基础，在双方都同意的情况下,指定时间与另一方进行金额交易。',
    pt: '随时随地多平台终端交易',
    smxz: '扫码下载'
  },
  market: {
    dsj: '大数据挖掘',
    wj: '前沿区块链',
    xhjy: '现货交易',
    qhjy: '期货交易',
    ss: '搜索您关心的币种',
    jyd: '交易对',
    zxj: '最新价',
    zdf: '涨跌幅',
    zgj: '最高价',
    zdj: '最低价',
    cjl: '成交量',
    cz: '操作',
    jy: '交易'
  },
  transaction: {
    kpj:'开盘价',
    ss: '搜索您关心的币种',
    bz: '币种',
    jg: '价格',
    zdf: '涨跌幅',
    zxcj: '最新成交',
    sl: '数量',
    sj: '时间',
    zgj: '最高价',
    zdj: '最低价',
    cjl: '成交量',
    cje: '成交额',
    xhjy: '现货交易',
    qhjy: '期货交易',
    ky: '可用',
    cb: '充币',
    mrj: '买入价',
    mrl: '买入量',
    mcj: '卖出价',
    mcl: '卖出量',
    mr: '买入',
    mc: '卖出',
    jye: '交易额',
    dqwt: '当前委托',
    lswt: '历史委托',
    zcgl: '资产管理',
    cx: '撤销',
    ywc: '已完成',
    ycx: '已撤销',
    xj: '现价',
    zs: '最少',
    ms: '秒',
    zc: '资产',
    dj: '冻结',
    yk: '盈亏',
    cb: '充币',
    mz: '买涨',
    md: '买跌',
    djs: '开盘倒计时',
    jyd: '交易对',
    lx: '类型',
    fx: '方向',
    ddsl: '订单数量',
    ycjsl: '已成交数量',
    je: '金额',
    cz: '操作',
    ze: '总额',
    kyye: '可用余额',
    djse: '冻结数额',
    kssj: '开始时间',
    ksjg: '开始价格',
    jssj: '结束时间',
    jsjg: '结束价格',
    yjyl: '预计盈利',
    mrsl: '买入数量',
    bdyl: '本单盈利',
    pk: '盘口',
    wtdd: '委托订单',
    fz: '分钟',
    xs: '小时',
    ri: '日',
    z: '周',
    yue: '月'
  },
  recharge: {
    cb: '充币',
    tit: '选择币种并复制钱包地址',
    tit2: '选择您要充值的币种及网络，并在本页复制钱包地址',
    tit3: '提现至钱包地址',
    tit4: '在其他交易所平台上粘贴已有复制的钱包地址，并提交提现申请',
    tit5: '转账确认',
    tit6: '等待区块网络确认转账',
    tit7: '提现至钱包地址',
    tit8: '区块网络确认，将完成转账到您的钱包地址',
    xx: '币种信息',
    ze: '总额',
    kyye: '可用余额',
    djye: '冻结余额',
    kcz: '可用的充值网络',
    ts: '请确保您在其他交易所平台选择的币种网络与本页已选的币种网络一致。',
    czdz: '充值地址',
    dz: '地址',
    wxts: '温馨提示',
    wxts2: '请勿向上述地址充值任何非',
    wxts3: '资产，否则资产将不可找回。',
    copy: '复制成功'
  },
  withdraw: {
    tx: '提币',
    tx2: '选择币种并确认提币地址',
    tx3: '选择您要充值的币种及网络，并在本页粘贴已复制的提币地址',
    tx4: '转账确认',
    tx5: '等待区块网络确认您的转账',
    tx6: '提币成功',
    tx7: '区块网络确认，将为您完成转账到提币地址',
    xx: '币种信息',
    ze: '总额',
    kyye: '可用余额',
    djye: '冻结余额',
    txdz: '提币地址',
    srdz: '请输入提币地址',
    txwl: '提币网络',
    txsl: '提币数量',
    zx: '最小单笔提币数量：',
    qb: '全部',
    sxf: '手续费',
    sjdz: '实际到账',
    jyma: '交易密码',
    qsr: '请输入交易密码',
    szma: '设置密码',
    wxts: '温馨提示',
    wxts2: '请认真核对币种及提币地址，提交后将无法撤回，提币审核时间为：工作日9:00-18:00。 ',
    tj: '提交'
  },
  down: {
    tit: '数字资产',
    tit2: '一站式',
    tit3: '交易平台',
    zd: '随时随地多平台终端交易',
    xz: '扫码下载',
  },
  assets: {
    tit: '我的资产',
    zh: ' 总资产折合',
    jl: '资产记录',
    zczh: '资产账户',
    qb: '钱包',
    bz: '币种',
    kyye: '可用余额',
    djse: '冻结数额',
    gz: '估值',
    cz: '操作',
    cb: '充币',
    tb: '提币',
    qjy: '去交易',
    sj: '时间',
    czse: '充值数额',
    dz: '区块交易地址',
    zt: '状态',
    ywc: '已完成',
    czjl: '充值记录',
    txjl: '提现记录',
    tbsl: '提币数量',
    tbdz: '提币地址',
    sxf: '手续费',
    jsse: '结算数额',
    zt: '状态'
  },
  order: {
    bbdd: '币币订单',
    qhdd: '期货订单',
    dqwt: '当前委托',
    lswt: '历史委托',
    jyd: '交易对',
    cjsj: '创建时间',
    fx: '方向',
    wtjg: '委托价格',
    ddsl: '订单数量',
    ycjsl: '已成交数量',
    wtje: '委托金额',
    cz: '操作',
    jyd: '交易对',
    ywc: '已完成',
    yqx: '已取消',
    cd: '撤单',
    zt: '状态',
    mr: '买',
    mc: '卖',
    mz: '买涨',
    md: '买跌',
    kssj: '开始时间',
    ksjg: '开始价格',
    jssj: '结束时间',
    jsjg: '结束价格',
    yjyl: '预计盈利',
    kpdjs: '开盘倒计时',
    bdyl: '本单盈利'
  },
  my: {
    sczp: '请上传证件照片',
    grzx: '个人中心',
    sfrz: '身份认证',
    aqzx: '安全中心',
    zh: '账号',
    zcsj: '注册时间',
    zjdl: '最近登录记录',
    sj: '时间',
    dd: '地点',
    ed: '当前提币额度',
    wc: '完成身份验证以提升您的安全等级和提币额度',
    rz: '实名认证',
    tged: "提高提币额度，24小时限额可增加至",
    qrz: '去认证',
    qxz: '请选择',
    xm: '姓名',
    zjhm: '证件号码',
    tjsh: '提交审核',
    dj: '当前账号风险等级',
    g: '高',
    z: '中',
    d: '低',
    dlma: '登录密码',
    dlma2: '提高密码强度有助于提升您的资金安全',
    yxyz: '邮箱验证',
    yxyz2: '您可以绑定一个常用邮箱，用于登录、找回密码',
    jyma: '交易密码',
    jyma2: '您可以设置一个交易密码，用于提币时的确认',
    xg: '修改',
    sz: '设置',
    ts: '提示:修改登录密码后24小时内禁止提现',
    qsr1: '请输入旧密码',
    qsr2: '请输入新密码',
    qsr3: '请再次确认密码',
    qx: '取消',
    qd: '确定',
    sryx: '请输入新邮箱地址',
    sryx2: '请输入新邮箱验证码',
    sryx3: '请输入旧邮箱验证码',
    szjyma: '设置交易密码',
    szjyma2: '确认交易密码',
    sryzm: '请输入邮箱验证码',
    yzm: '验证码',
    cjrz: '认证',
    xqyx: '修改邮箱',
    xs: '姓氏',
    mz: '名字',
    yrz: '已认证',
    yrz1: '待审核',
    lyfk: '留言反馈',
    lytext: '留言',
    hftext: '反馈',
  }
}
