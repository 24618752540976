import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/reset.css'
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';
import { Button} from 'element-ui';
import { Message } from 'element-ui'
Vue.prototype.$message = Message
import VueI18n from 'vue-i18n';
import axios from 'axios'
Vue.prototype.$axios = axios

import '@/utils/flexible.js'

Vue.use(Button)
Vue.use(VueI18n)
// 多语言切换
const i18n = new VueI18n({
	locale:sessionStorage.getItem('lang')||'en', //设置默认语言
	messages:{
		'zh-CN': require('@/assets/lang/zh-CN'),
		'zh-CN2': require('@/assets/lang/zh-CN2'),
		'en': require('@/assets/lang/en'),
		'French': require('@/assets/lang/French'),
		'Japanese': require('@/assets/lang/Japanese'),
		'Deutsch': require('@/assets/lang/Deutsch'),
		'Spanish': require('@/assets/lang/Spanish')
	}
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
