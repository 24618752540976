module.exports = {
	language: {
	    name: '西班牙'
	},

  common_msg:{
    success: '¡ la operación fue exitosa!',
    error: "la operación falló, por favor vuelva a intentarlo más tarde",
  },
  trade_spot:{
    msgBuyPrice: "¡ introduzca el precio de compra correcto!",
    msgBuyNum: "¡ introduzca la cantidad correcta de compra!",
    msgSellPrice: '¡ introduzca el precio de venta correcto!',
    msgSellNum: '¡ introduzca la cantidad correcta de ventas!',
    msgUstdNotEnough: "¡ no hay suficientes usdt disponibles!",
    msgSellNumNotEnough: "¡ el número de ventas es insuficiente!",
  },
  download_text:{
    android:'Android',
    iphone:'IPhone',
  },
	login: {
		title: 'Bienvenido a iniciar sesión',
        yhm:'Nombre de usuario',
		username: 'Introduzca el nombre de usuario',
        ma:'Contraseña',
        pwd:'Introduzca la contraseña',
        zc:'Registro',
        wj:'Olvida la contraseña',
        dl:'Iniciar sesión',
        cz:'No se permite el retiro de efectivo dentro de las 24 horas posteriores al restablecimiento de la contraseña',
        yxdz:'Dirección del buzón',
        srdz:'Introduzca su número de buzón',
        yxyz:'Verificación del buzón',
        yzm:'Código de verificación',
        send:'Enviar',
        next:'Siguiente paso',
        czma:'Restablecer la contraseña',
        xmm:'Nueva contraseña',
        szxmm:'Establecer una nueva contraseña',
        qrma:'Confirmar contraseña',
        zcqr:'Vuelva a introducir la nueva contraseña',
        qd:'Determinar',
        cj:'Crear su cuenta',
        sz:'Establecer contraseña',
        yyzh:'Cuenta existente',
        qdl:'Ir a iniciar sesión',
	},
    nav:{
        sy:'Página de inicio',
        sc:'Mercado',
        bbjy:'Transacciones',
        qhjy:'Futuros',
        xz:'Descargar',
        cb:'Recargar',
        tb:'Mencionar',
        zc:'Activos',
        da:'Pedidos',
        dl:'Iniciar sesión',
        reg:'Registro',
        out:'Salida'
    },
    footer:{
       sq:'Comunidad',
       zc:'Apoyo',
       bzzx:'Centro de ayuda',
       hysm:'Descripción del contrato',
       gywm:'Sobre nosotros',
       fvbz:'Normas de tarifas',
       sbsq:'Solicitud en moneda',
       tk:'Cláusula',
       flsm:'Declaración legal',
       fxts:'Consejos de riesgo',
       yszc:'Política de privacidad',
       yhxy:'Protocolo de usuario',
       lxwm:'Contáctanos',
       swhz:'Cooperación comercial',
       kfyx:'Buzón de servicio al cliente'
    },
    index:{
        qq:'Global',
        aq:'Máximo y más seguro',
        jys:'Bolsa de moneda virtual',
        zc:'Cuenta registrada',
        rbb:'Lista de monedas calientes',
        xbb:'Lista de nuevas monedas',
        zfb:'Lista de ganancias diarias',
        jyd:'Transacciones',
        jg:'Precio',
        zdf:'Subidas y bajadas',
        hq:'Mercado',
        xq:'Detalles',
        ckgd:'Ver más',
        xzwm:'Por qué elegirnos',
        x1:'Garantía de seguridad y fondos legales',
        x2:'La seguridad de sus activos está garantizada a través de los medios duales de centralización y descentralización, y es la única plataforma comercial en el mundo que invierte cientos de millones de dólares para proporcionar "fondos legales y de seguridad" para la seguridad de sus activos.',
        x3:'Sistema de carga del sistema de comercio rápido',
        x4:'La acumulación tecnológica duradera le proporciona un sistema de comercio en tiempo real eficiente y conveniente. Su entrada y salida de fondos también se puede cargar en segundos.',
        x5:'Como siempre, la estabilidad es duradera.',
        x6:'¡Después de 9 años de operación estable, hemos acumulado muchas tecnologías líderes en la industria y seguimos proporcionándole servicios estables. ¡ estamos llenos de confianza y trabajamos duro por ello!',
        x7:'Su plataforma de confianza',
        x8:'Como una de las plataformas comerciales más largas y voluminosas del mundo, sigue estrictamente las reglas de la industria y no participa en el funcionamiento del mercado. La apertura, la transparencia, la igualdad y la cooperación siempre han sido nuestra misión.',
        ys:'Nuestra ventaja',
        y1:'Seguridad',
        y2:'La tecnología de Seguridad líder de la industria y la estabilidad financiera garantizan la seguridad de sus activos.',
        y3:'Libertad',
        y4:'Se puede llevar a cabo una operación de apertura y cierre de posiciones tipo rayo, y mucho menos un mecanismo de reparto, y los fondos de ganancias comerciales de los usuarios se pueden controlar al 100% libremente.',
        y5:'Rápido',
        y6:'Proyectos de moneda eficientes, nuevos y de alta calidad con excelente liquidez.',
        cp:'Nuestros productos',
        c1:'Transacciones al contado',
        c2:'Más de 1000 criptomonedas para que negocie al mejor precio',
        c3:'Comercio de futuros',
        c4:'El comercio de futuros es un derivado financiero que se negocia en relación con el mercado al contado.',
        c5:'Transacciones contractuales',
        c6:'La transacción contractual se basa en un contrato a plazo al contado, con el consentimiento de ambas partes, designando un tiempo para realizar la transacción de cantidad con la otra parte.',
        pt:'Transacciones de terminales multiplataforma',
        smxz:'Descarga de código de escaneo'
    },
    market:{
        dsj:'Minería de Big data',
        wj:'Cadena de bloques de vanguardia',
        xhjy:'Transacciones al contado',
        qhjy:'Comercio de futuros',
        ss:'Buscando la moneda que le importa',
        jyd:'Transacciones',
        zxj:'El último precio',
        zdf:'Subidas y bajadas',
        zgj:'Precio máximo',
        zdj:'Mínimo',
        cjl:'Volumen de Negocios',
        cz:'Operación',
        jy:'Transacciones'
    },
    transaction:{
      kpj:'Precio de apertura',
        ss:'Buscando la moneda que le importa',
        bz:'Moneda',
        jg:'Precio',
        zdf:'Subidas y bajadas',
        zxcj:'La última transacción',
        sl:'Cantidad',
        sj:'Tiempo',
        zgj:'Precio máximo',
        zdj:'Mínimo',
        cjl:'Volumen de Negocios',
        cje:'Volumen de Negocios',
        xhjy:'Transacciones al contado',
        qhjy:'Comercio de futuros',
        ky:'Disponible',
        cb:'Recargar monedas',
        mrj:'Precio de compra',
        mrl:'Volumen de compra',
        mcj:'Precio de venta',
        mcl:'Volumen de ventas',
        mr:'Comprar',
        mc:'Vender',
        jye:'Volumen de transacciones',
        dqwt:'Mandato actual',
        lswt:'Encargo histórico',
        zcgl:'Gestión de activos',
        cx:'Revocación',
        ywc:'Completado',
        ycx:'Revocado',
        xj:'Precio actual',
        zs:'Mínimo',
        ms:'Segundos',
        zc:'Activos',
        dj:'Congelación',
        yk:'Ganancias y pérdidas',
        cb:'Recargar monedas',
        mz:'Comprar y subir',
        md:'Comprar y caer',
        djs:'Cuenta atrás',
        jyd:'Transacciones',
        lx:'Tipo',
        fx:'Dirección',
        ddsl:'Número de pedidos',
        ycjsl:'Número de transacciones realizadas',
        je:'Importe',
        cz:'Operación',
        ze:'Total',
        kyye:'Saldo disponible',
        djse:'Importe congelado',
        kssj:'Hora de inicio',
        ksjg:'Precio de inicio',
        jssj:'Fin del tiempo',
        jsjg:'Precio final',
        yjyl:'Ganancias previstas',
        mrsl:'Número de compras',
        bdyl:'Ganancias de este pedido',
        pk:'Pankou',
        wtdd:'Pedidos confiados',
        fz:'Min',
        xs:'Horas',
        ri:'Día',
        z:'Zhou',
        yue:'Mes'
    },
    recharge:{
        cb:'Recargar monedas',
        tit:'Seleccione la moneda y copie la dirección de la billetera',
        tit2:'Seleccione la moneda y la red que desea recargar y copie la dirección de la billetera en esta página',
        tit3:'Retiro a la dirección de la billetera',
        tit4:'Pegar la dirección de la billetera copiada en otras plataformas de bolsa y presentar la solicitud de retiro de efectivo',
        tit5:'Confirmación de la transferencia',
        tit6:'Esperar a que la red de bloques confirme la transferencia',
        tit7:'Retiro a la dirección de la billetera',
        tit8:'Confirmación de la red de bloques, se completará la transferencia a la dirección de su billetera',
        xx:'Información monetaria',
        ze:'Total',
        kyye:'Saldo disponible',
        djye:'Saldo congelado',
        kcz:'Redes de recarga disponibles',
        ts:'Asegúrese de que la red de monedas que ha seleccionado en otras plataformas de intercambio es consistente con la red de monedas elegidas en esta página.。',
        czdz:'Dirección de recarga',
        dz:'Dirección',
        wxts:'Consejos cálidos',
        wxts2:'No recargue ningún no a la dirección anterior',
        wxts3:'Activos, de lo contrario los activos no serán recuperables.',
        copy:'Copia exitosa'
    },
    withdraw:{
        tx:'Retiro de efectivo',
        tx2:'Seleccione la moneda y confirme la dirección de retiro de efectivo',
        tx3:'Seleccione la moneda y la red que desea recargar y pegue la dirección de retiro copiada en esta página.',
        tx4:'Confirmación de la transferencia',
        tx5:'Espere a que la red de bloques confirme su transferencia',
        tx6:'Retiro exitoso',
        tx7:'Confirmación de la red de bloques, que completará la transferencia a la dirección de retiro de efectivo para usted',
        xx:'Información monetaria',
        ze:'Total',
        kyye:'Saldo disponible',
        djye:'Saldo congelado',
        txdz:'Dirección de retiro de efectivo',
        srdz:'Por favor, introduzca la red de retiro de efectivo',
        txwl:'Red de retiro de efectivo',
        txsl:'Número de retiros de efectivo',
        zx:'Número mínimo de retiros individuales:',
        qb:'Todo',
        sxf:'Comisiones',
        sjdz:'Llegada real a la cuenta',
        jyma:'Contraseña de transacción',
        qsr:'Introduzca la contraseña de la transacción',
        szma:'Establecer contraseña',
        wxts:'Consejos cálidos',
        wxts2:'Por favor, verifique cuidadosamente la moneda y la dirección de la moneda, que no se puede retirar después de la presentación, y el tiempo de revisión de la moneda es de 9: 00 a 18: 00 los días laborables. ',
        tj:'Presentación'
    },
    down:{
        tit:'Activos digitales',
        tit2:'Ventanilla única',
        tit3:'Plataforma de Comercio',
        zd:'Transacciones de terminales multiplataforma en cualquier momento y en cualquier lugar',
        xz:'Descarga de código de escaneo',
    },
    assets:{
        tit:'Mis activos',
        zh:' Conversión de activos totales',
        jl:'Registro de activos',
        zczh:'Cuenta de activos',
        qb:'Billetera',
        bz:'Moneda',
        kyye:'Saldo disponible',
        djse:'Importe congelado',
        gz:'Valoración',
        cz:'Operación',
        cb:'Recargar',
        tb:'Mencionar',
        qjy:'Transacciones',
        sj:'Tiempo',
        czse:'Cantidad recargada',
        dz:'Dirección',
        ywc:'Completado',
        czjl:'Registro de recarga',
        txjl:'Registro de retiro de efectivo',
        tbsl:'Número de monedas retiradas',
        tbdz:'Dirección de la moneda',
        sxf:'Comisiones',
        jsse:'Importe liquidado',
        zt:'Estado'
    },
    order:{
       bbdd:'Pedidos de monedas y monedas',
       qhdd:'Pedidos de futuros',
       dqwt:'Mandato actual',
       lswt:'Encargo histórico',
       jyd:'Transacciones',
       cjsj:'Crear',
       fx:'Dirección',
       wtjg:'Precio confiado',
       ddsl:'Número de pedidos',
       ycjsl:'Número de transacciones',
       wtje:'Importe',
       cz:'Operación',
       jyd:'Transacciones',
       ywc:'Completado',
       yqx:'Cancelado',
       cd:'Retiro',
       zt:'Estado',
       mr:'Comprar',
       mc:'Vender',
       mz:'Comprar y subir',
       md:'Comprar y caer',
       kssj:'Hora de inicio',
       ksjg:'Precio de inicio',
       jssj:'Fin del tiempo',
       jsjg:'Precio final',
       yjyl:'Ganancias previstas',
       kpdjs:'Cuenta atrás',
       bdyl:'Ganancias'
    },
    my:{
      sczp: 'Por favor, suba la foto del documento.',
       grzx:'Centro personal',
       sfrz:'Identidad',
       aqzx:'Centro de Seguridad',
       zh:'Cuenta',
       zcsj:'Tiempo de registro',
       zjdl:'Registro',
       sj:'Tiempo',
       dd:'Lugar',
       ed:'Cuota actual de retiro de moneda',
       wc:'Completar la autenticación para mejorar su nivel de Seguridad y la cuota de retiro de monedas',
       rz:'Autenticación de nombre real',
       tged:"Aumentar el límite de retiro de moneda, el límite de 24 horas se puede aumentar a",
       qrz:'Ir a la certificación',
       qxz:'Por favor, elija',
       xm:'Nombre',
       zjhm:'Número de documento',
       tjsh:'Presentación para su revisión',
       dj:'Nivel de riesgo de la cuenta actual',
       g:'Alto',
       z:'Medio',
       d:'Bajo',
       dlma:'Contraseña de inicio de sesión',
       dlma2:'Aumentar la intensidad de la contraseña ayuda a mejorar su seguridad financiera',
       yxyz:'Verificación del buzón',
       yxyz2:'Puede vincular un buzón de uso común para iniciar sesión y recuperar la contraseña',
       jyma:'Contraseña de transacción',
       jyma2:'Puede configurar una contraseña de transacción para confirmar al retirar la moneda',
       xg:'Modificación',
       sz:'Configuración',
       ts:'Consejo: se prohíbe el retiro de efectivo dentro de las 24 horas posteriores a modificar la contraseña de inicio de sesión',
       qsr1:'Por favor, introduzca la contraseña antigua',
       qsr2:'Por favor, introduzca una nueva contraseña',
       qsr3:'Por favor, confirme la contraseña nuevamente.',
       qx:'Cancelación',
       qd:'Determinar',
       sryx:'Por favor, introduzca la nueva dirección de correo electrónico',
       sryx2:'Introduzca el nuevo Código de verificación del buzón',
       sryx3:'Por favor, introduzca el Código de verificación del buzón antiguo.',
       szjyma:'Establecer la contraseña de la transacción',
       szjyma2:'Confirmar la contraseña de la transacción',
       sryzm:'Verificación',
       yzm:'Enviar',
       cjrz:'Certificación primaria',
       xqyx:'Modificar el buzón',
       xs:'Apellidos',
       mz:'Nombre',
      yrz: 'Ha sido certificado',
      yrz1: 'To be reviewed',
      lyfk: 'Comentarios',
      lytext: 'Comentarios',
      hftext: 'retroalimentación',
    }
}
