module.exports = {
	language: {
	    name: '法语'
	},

  common_msg:{
    success: 'opération réussie!',
    error: "l'opération a échoué, Veuillez réessayer plus tard.",
  },
  trade_spot:{
    msgBuyPrice: "veuillez entrer le prix d'achat correct!",
    msgBuyNum: "s'il vous plaît entrer la bonne quantité d'achat!",
    msgSellPrice: 'veuillez saisir le prix de vente correct!',
    msgSellNum: 'veuillez saisir la bonne quantité vendue!',
    msgUstdNotEnough: "il n'y a pas assez d'usdt disponible!",
    msgSellNumNotEnough: "il n'y a pas assez de quantité vendable!",
  },
  download_text:{
    android:'Android',
    iphone:'IPhone',
  },

	login: {
		title: 'Bienvenue login',
        yhm:'Nom dutilisateur',
		username: 'Entrez un nom dutilisateur',
        ma:'Mot de passe',
        pwd:'Entrez le mot de passe',
        zc:'Enregistrement',
        wj:'Mot de passe oublié',
        dl:'Se connecter',
        cz:'Retrait non autorisé pendant 24 heures après la Réinitialisation du mot de passe',
        yxdz:'Adresse de boîte aux lettres',
        srdz:'Entrez votre numéro de boîte aux lettres',
        yxyz:'Vérification de la boîte aux lettres',
        yzm:'Code de vérification',
        send:'Envoyer',
        next:'Prochaines étapes',
        czma:'Réinitialiser le mot de passe',
        xmm:'Nouveau mot de passe',
        szxmm:'Définir un nouveau mot de passe',
        qrma:'Confirmer le mot de passe',
        zcqr:'Entrez à nouveau votre nouveau mot de passe',
        qd:'Déterminer',
        cj:'Créer votre compte',
        sz:'Définir un mot de passe',
        yyzh:'Déjà un numéro de compte',
        qdl:'Aller au login',
	},
    nav:{
        sy:'Accueil',
        sc:'Le marché',
        bbjy:'Monnaie',
        qhjy:'Futures',
        xz:'Télécharger',
        cb:'Chargement',
        tb:'Mentionner',
        zc:'Actifs',
        da:'Commandes',
        dl:'Se connecter',
        reg:'Enregistrement',
        out:'Quitter'
    },
    footer:{
       sq:'La communauté',
       zc:'Soutien',
       bzzx:'Centre daide',
       hysm:'Description du contrat',
       gywm:'À propos de nous',
       fvbz:'Normes tarifaires',
       sbsq:'Demande de monnaie supérieure',
       tk:'Termes',
       flsm:'Déclaration légale',
       fxts:'Conseils de risque',
       yszc:'Politique de confidentialité',
       yhxy:'Accord utilisateur',
       lxwm:'Contactez - nous',
       swhz:'Coopération commerciale',
       kfyx:'Boîte aux lettres du service à la clientèle'
    },
    index:{
        qq:'Dans le monde entier',
        aq:'Le plus grand et le plus sûr',
        jys:'Échange de devises virtuelles',
        zc:'Numéro de compte enregistré',
        rbb:'Liste des hot coins',
        xbb:'Liste des nouvelles monnaies',
        zfb:'Liste des gains quotidiens',
        jyd:'Paire de transactions',
        jg:'Prix',
        zdf:'Hausse et baisse',
        hq:'Le comportement',
        xq:'Détails',
        ckgd:'Voir plus',
        xzwm:'Pourquoi nous choisir',
        x1:'Fonds pour la sécurité et létat de droit toutes garanties',
        x2:'Sécurisez vos actifs grâce à une approche centralisée et décentralisée, et cest la seule plateforme de Trading au monde qui investit des centaines de millions de dollars dans un « Fonds sécurisé et légal» pour la sécurité de vos actifs.',
        x3:'Système de recharge rapide des transactions',
        x4:'Laccumulation technologique de longue date vous offre un système de Trading en temps réel efficace et pratique. Vos fonds peuvent également être transférés en secondes.',
        x5:'Stable comme toujours',
        x6:'9 ans dexploitation stable, accumulé de nombreuses technologies de pointe de lindustrie, continuer à vous fournir un service stable, nous sommes confiants et nous nous efforçons de lutter pour elle!',
        x7:'Une plateforme sur laquelle vous pouvez compter',
        x8:'En tant que lune des plateformes de Trading les plus anciennes et les plus négociées au monde, elle suit strictement les règles de lindustrie et ne participe pas aux opérations du marché. Ouverture, transparence, égalité, coopération ont toujours été notre mission.',
        ys:'Nos avantages',
        y1:'Sécurité',
        y2:'Technologie de sécurité de pointe et stabilité financière pour assurer la sécurité de vos actifs.',
        y3:'Liberté',
        y4:'Lopération douverture et de fermeture de position éclair peut être effectuée, plus aucun mécanisme de partage, les fonds rentables de négociation des utilisateurs peuvent être à 100% discrétionnaires.',
        y5:'Rapide',
        y6:'Nouveaux projets de devises efficaces et de haute qualité avec une excellente liquidité.',
        cp:'Nos produits',
        c1:'Transactions au comptant',
        c2:'Plus de 1000 crypto - monnaies à trader au meilleur prix',
        c3:'Trading à terme',
        c4:'Le Trading à terme est un dérivé financier, cest le trading par rapport au marché au comptant',
        c5:'Transactions contractuelles',
        c6:'Les transactions contractuelles sont basées sur des contrats à terme au comptant et, avec laccord des deux parties, spécifient un moment pour négocier un montant avec lautre partie.',
        pt:'Trading terminal multiplateforme à tout moment, nimporte où',
        smxz:'Scan code télécharger'
    },
    market:{
        dsj:'Big Data Mining',
        wj:'Blockchain à la pointe',
        xhjy:'Transactions au comptant',
        qhjy:'Trading à terme',
        ss:'Rechercher les devises qui vous intéressent',
        jyd:'Paire de transactions',
        zxj:'Derniers prix',
        zdf:'Hausse et baisse',
        zgj:'Le prix le plus élevé',
        zdj:'Le prix le plus bas',
        cjl:'Volume',
        cz:'Opérations',
        jy:'Transactions'
    },
    transaction:{
      kpj:"Prix d'ouverture",
        ss:'Rechercher les devises qui vous intéressent',
        bz:'Monnaie',
        jg:'Prix',
        zdf:'Hausse et baisse',
        zxcj:'Dernières transactions',
        sl:'Quantité',
        sj:'Le temps',
        zgj:'Haute',
        zdj:'Faible',
        cjl:'Volume',
        cje:'Chiffre daffaires',
        xhjy:'Transactions au comptant',
        qhjy:'Trading à terme',
        ky:'Disponible',
        cb:'Remplissage de monnaie',
        mrj:'Prix dachat',
        mrl:'Volume des achats',
        mcj:'Prix de vente',
        mcl:'Volume vendu',
        mr:'Acheter',
        mc:'Vendre',
        jye:'Volume des transactions',
        dqwt:'Mandat actuel',
        lswt:'Commission historique',
        zcgl:'Gestion des actifs',
        cx:'Révocation',
        ywc:'Terminé',
        ycx:'A été révoqué',
        xj:'Prix actuel',
        zs:'Le moins',
        ms:'second',
        zc:'Actifs',
        dj:'Congelé',
        yk:'Gain et perte',
        cb:'Remplissage de monnaie',
        mz:'Acheter pour augmenter',
        md:'Acheter en baisse',
        djs:'Compte à rebours douverture',
        jyd:'Paire de transactions',
        lx:'Type',
        fx:'Direction',
        ddsl:'Nombre de commandes',
        ycjsl:'Nombre de transactions réalisées',
        je:'Montant',
        cz:'Opérations',
        ze:'Total',
        kyye:'Solde disponible',
        djse:'Montant gelé',
        kssj:'Heure de début',
        ksjg:'Prix de départ',
        jssj:'Heure de fin',
        jsjg:'Prix de fin',
        yjyl:'Bénéfices attendus',
        mrsl:'Nombre dachats',
        bdyl:'Bénéfice de cette commande',
        pk:'Port de disque',
        wtdd:'Commande déléguée',
        fz:'minute',
        xs:'hour',
        ri:'day',
        z:'week',
        yue:'month'
    },
    recharge:{
        cb:'Remplissage de monnaie',
        tit:'Sélectionnez la devise et copiez ladresse du portefeuille',
        tit2:'Sélectionnez la devise et le réseau que vous souhaitez recharger et copiez ladresse du portefeuille sur cette page',
        tit3:'Retrait à ladresse du portefeuille',
        tit4:'Collez une adresse de portefeuille déjà copiée sur dautres plateformes déchange et soumettez une demande de retrait',
        tit5:'Confirmation du transfert',
        tit6:'Attendez que le réseau de blocs confirme le transfert',
        tit7:'Retrait à ladresse du portefeuille',
        tit8:'Block Network confirme que le transfert sera effectué à votre adresse de portefeuille',
        xx:'Informations sur la devise',
        ze:'Total',
        kyye:'Solde disponible',
        djye:'Geler le solde',
        kcz:'Réseau de recharge disponible',
        ts:'Sil vous plaît assurez - vous que le réseau de devises que vous sélectionnez sur lautre plate - forme déchange correspond à celui que vous avez déjà sélectionné sur cette page.',
        czdz:'Adresse de recharge',
        dz:'Adresse',
        wxts:'Conseils chaleureux',
        wxts2:'Ne rechargez pas les adresses ci - dessus avec des non',
        wxts3:'Actifs, sinon les actifs ne seront pas récupérables.',
        copy:'Copie réussie'
    },
    withdraw:{
        tx:'Présente',
        tx2:'Sélectionnez la devise et confirmez ladresse de retrait',
        tx3:'Sélectionnez la devise et le réseau que vous souhaitez recharger et collez ladresse de retrait copiée sur cette page',
        tx4:'Confirmation du transfert',
        tx5:'Attendez que le réseau de blocs confirme votre transfert',
        tx6:'Présenter le succès',
        tx7:'Block Network confirme que le transfert sera effectué pour vous à ladresse de retrait',
        xx:'Informations sur la devise',
        ze:'Total',
        kyye:'Solde disponible',
        djye:'Geler le solde',
        txdz:'Adresse de retrait',
        srdz:'Veuillez entrer le réseau de retrait',
        txwl:'Réseau de découverte',
        txsl:'Nombre de retraits',
        zx:'Nombre minimum de retraits simples:',
        qb:'Tous',
        sxf:'Frais de traitement',
        sjdz:'Arrivée réelle au compte',
        jyma:'Mot de passe de transaction',
        qsr:'Veuillez entrer le mot de passe de la transaction',
        szma:'Définir un mot de passe',
        wxts:'Conseils chaleureux',
        wxts2:'Sil vous plaît vérifier soigneusement la devise et ladresse de retrait, il ne sera pas possible de retirer après la soumission, lheure dexamen du retrait est: 9: 00 - 18: 00 les jours ouvrables. ',
        tj:'Soumettre'
    },
    down:{
        tit:'Actifs numériques',
        tit2:'Un seul arrêt',
        tit3:'Plateforme de Trading',
        zd:'Trading terminal multiplateforme à tout moment, nimporte où',
        xz:'Scan code télécharger',
    },
    assets:{
        tit:'Mes actifs',
        zh:' Total des actifs repliés',
        jl:'Enregistrement des actifs',
        zczh:'Comptes dactifs',
        qb:'Portefeuille',
        bz:'Monnaie',
        kyye:'Solde disponible',
        djse:'Montant gelé',
        gz:'Évaluation',
        cz:'Opérations',
        cb:'Remplissage de monnaie',
        tb:'Levée de monnaie',
        qjy:'Aller au Trading',
        sj:'Le temps',
        czse:'Montant de la recharge',
        dz:'Adresse de transaction de bloc',
        zt:'Statut',
        ywc:'Terminé',
        czjl:'Enregistrement de recharge',
        txjl:'Relevé des enregistrements',
        tbsl:'Quantité de monnaie',
        tbdz:'Adresse de dépôt de monnaie',
        sxf:'Frais de traitement',
        jsse:'Montant du Règlement',
        zt:'Statut'
    },
    order:{
       bbdd:'Ordre de pièces de monnaie',
       qhdd:'Ordres à terme',
       dqwt:'Mandat actuel',
       lswt:'Commission historique',
       jyd:'Paire de transactions',
       cjsj:'Temps de création',
       fx:'Direction',
       wtjg:'Prix',
       ddsl:'Quantité',
       ycjsl:'Clôture',
       wtje:'amount',
       cz:'Opérations',
       jyd:'Paire de transactions',
       ywc:'Terminé',
       yqx:'Annulé',
       cd:'Ordre de retrait',
       zt:'Statut',
       mr:'Acheter',
       mc:'Vendre',
       mz:'Augmenter',
       md:'Tomber',
       kssj:'Heure de début',
       ksjg:'Prix de départ',
       jssj:'Heure de fin',
       jsjg:'Prix de fin',
       yjyl:'Bénéfices attendus',
       kpdjs:'Compte à rebours',
       bdyl:'Bénéfice de cette commande'
    },
    my:{
        grzx:'Centre personnel',
        sfrz:'Authentification didentité',
        aqzx:'Centre de sécurité',
        zh:'Numéro de compte',
        zcsj:'Heures dinscription',
        zjdl:'Enregistrement de connexion récent',
        sj:'Le temps',
        dd:'Localisation',
        ed:'Montant actuel des devises',
        wc:'Complétez lauthentification pour augmenter votre niveau de sécurité et votre limite de retrait',
        rz:'Certification du vrai nom',
        tged:"Augmenter la limite de retrait, la limite de 24 heures peut être augmentée à",
        qrz:'Aller à la certification',
        qxz:'Veuillez sélectionner',
        xm:'Nom et prénom',
        zjhm:'Numéro de document',
        tjsh:'Soumettre un audit',
        dj:'Niveau de risque du numéro de compte actuel',
        g:'Haute',
        z:'Au milieu',
        d:'Faible',
        dlma:'Login mot de passe',
        dlma2:'Augmenter la force de votre mot de passe contribue à la sécurité de votre argent',
        yxyz:'Vérification de la boîte aux lettres',
        yxyz2:'Vous pouvez lier une boîte aux lettres habituelle pour vous connecter, récupérer votre mot de passe',
        jyma:'Mot de passe de transaction',
        jyma2:'Vous pouvez définir un mot de passe de transaction pour la confirmation lors de la remise de monnaie',
        xg:'Modifier',
        sz:'Paramètres',
        ts:'Conseil: les retraits sont interdits pendant 24 heures après la modification du mot de passe de connexion',
        qsr1:'Veuillez entrer votre ancien mot de passe',
        qsr2:'Veuillez entrer un nouveau mot de passe',
        qsr3:'Veuillez confirmer à nouveau votre mot de passe',
        qx:'Annulation',
        qd:'Déterminer',
        sryx:'Veuillez entrer une nouvelle adresse e - mail',
        sryx2:'Veuillez entrer un nouveau Code de vérification de boîte aux lettres',
        sryx3:'Veuillez entrer le Code de vérification de votre ancienne boîte aux lettres',
        szjyma:'Définir un mot de passe de transaction',
        szjyma2:'Confirmer le mot de passe de la transaction',
        sryzm:'Code de vérification',
        yzm:'Envoyer',
        cjrz:'Certification primaire',
        xqyx:'Modifier la boîte aux lettres',
        xs:'Nom de famille',
        mz:'Nom',
      yrz: 'A été certifié',
      sczp: 'Veuillez télécharger la photo didentification',
      yrz1: 'To be reviewed',
      lyfk: 'Laissez un message.',
      lytext: 'Le message',
      hftext: 'Les réactions',
     }
}
