<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {createSocket} from "@/utils/socket";

export default{
  mounted() {
    createSocket()
  }
}
</script>
<style>
  html {
  background: #171e28;
}

</style>
