import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
import Home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/reg',
    name: 'Reg',
    component: () => import('../views/login/reg.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/login/forget.vue')
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('../views/home/market.vue')
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/home/download.vue')
  },
  {
    path: '/my',
    name: 'My',
    component: () => import('../views/my/my.vue')
  },
  {
    path: '/realname',
    name: 'Realname',
    component: () => import('../views/my/realname.vue')
  },
  {
    path: '/editpwd',
    name: 'Editpwd',
    component: () => import('../views/my/editpwd.vue')
  },
  {
    path: '/assets',
    name: 'Assets',
    component: () => import('../views/home/assets.vue')
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('../views/home/recharge.vue')
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import('../views/home/withdraw.vue')
  },
  {
    path: '/assetsRecord',
    name: 'AssetsRecord',
    component: () => import('../views/home/assetsRecord.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/home/order.vue')
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import('../views/home/transaction.vue')
  },
  {
    path: '/futures',
    name: 'Futures',
    component: () => import('../views/home/futures.vue')
  }


]

const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {
//   const isLogin = sessionStorage.getItem('token');
//   if (to.path === '/login') {
//     next();
//   } else {
//     if (!isLogin) {
//       next('/login');
//     } else {
//       next();
//     }
//   }
// })
export default router
