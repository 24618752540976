module.exports = {
  common_msg:{
    success:'Operation succeeded！',
    error:'The operation failed. Please try again later.'
  },
  trade_spot:{
    msgBuyPrice:'Please enter the correct purchase price！',
    msgBuyNum:'Please enter the correct purchase quantity！',
    msgSellPrice:'Please enter the correct selling price！',
    msgSellNum:'Please enter the correct selling quantity！',
    msgUstdNotEnough:'Not enough available USDTs！',
    msgSellNumNotEnough:'Insufficient saleable quantity！',
  },
  download_text:{
    android:'Android',
    iphone:'IPhone',
  },

	language: {
	    name: 'English'
	},
	login: {
		title: 'Welcome to login',
        yhm:'User name',
		username: 'Enter one user name',
        ma:'Password',
        pwd:'Input password',
        zc:'Register',
        wj:'Forgot password',
        dl:'Sign in',
        cz:'Cash withdrawal is not allowed within 24 hours after password reset',
        yxdz:'E-mail address',
        srdz:'Enter your email',
        yxyz:'Mailbox verification',
        yzm:'Verification Code',
        send:'Send',
        next:'Next step',
        czma:'Reset password',
        xmm:'New password',
        szxmm:'Set new password',
        qrma:'Confirm Password',
        zcqr:'Enter the new password again',
        qd:'Determine',
        cj:'Create your account',
        sz:'Set password',
        yyzh:'Existing account',
        qdl:'Go to login',
	},
    nav:{
        sy:'Home',
        sc:'Market',
        bbjy:'Trade',
        qhjy:'Futures',
        xz:'Download',
        cb:'Deposit',
        tb:'Withdraw',
        zc:'Assets',
        da:'Order',
        dl:'Sign in',
        reg:'Register',
        out:'Log out'
    },
    footer:{
       sq:'Community',
       zc:'Support',
       bzzx:'Help Center',
       hysm:'Contract description',
       gywm:'About us',
       fvbz:'Rate standard',
       sbsq:'Apply for currency',
       tk:'Clause',
       flsm:'Legal statement',
       fxts:'Risk statement',
       yszc:'Privacy Policy',
       yhxy:'User Agreement',
       lxwm:'Contact us',
       swhz:'Business cooperation',
       kfyx:'Customer service mailbox'
    },
    index:{
        qq:'Coinfam is a',
        aq:' Famous ',
        jys:'Trading center',
        zc:'Registered account',
        rbb:'Hot coin list',
        xbb:'New currency list',
        zfb:'Daily increase list',
        jyd:'Transaction pair',
        jg:'Price',
        zdf:'Up and down range',
        hq:'Quotation',
        xq:'Details',
        ckgd:'See more',
        xzwm:'Why choose us',
        x1:'All-round security and legal system fund',
        x2:'It ensures your asset security by means of centralization and decentralization, and is the only trading platform in the world that invests hundreds of millions of yuan to provide "security and legal fund" for your asset security.',
        x3:'Fast trade and withdraw system',
        x4:'The long-term technology accumulation provides you with an efficient and convenient real-time trading system. Your funds can also be charged and withdrawn every second。',
        x5:'As always, it is durable and stable',
        x6:'After 9 years of stable operation, we have accumulated many industry-leading technologies and continue to provide you with stable services. We are full of confidence and strive for it!',
        x7:'Your trusted platform',
        x8:'As one of the trading platforms with the longest history and the largest trading volume in the world, it strictly follows the industry rules and does not participate in the market operation. Openness, transparency, equality and cooperation have always been our mission.',
        ys:'Our advantages',
        y1:'Security',
        y2:'The industry-leading security technology and financial stability ensure that your assets are safe and secure.',
        y3:'Free',
        y4:'It can open and close positions in a flash manner, and there is no allocation mechanism. The user trading profit funds can be 100% freely disposed.',
        y5:'Quick',
        y6:'Efficient and high-quality currency projects with excellent liquidity.',
        cp:'Our products',
        c1:'Spot trading',
        c2:'More than 1000 cryptocurrencies for you to trade at the best price',
        c3:'Futures trade',
        c4:'Futures trading is a financial derivative, which is relative to the spot market',
        c5:'Contract transaction',
        c6:'The contract transaction is based on the spot forward contract. With the agreement of both parties, the amount transaction is carried out with the other party at a specified time.',
        pt:'Multi-platform terminal transaction anytime, anywhere',
        smxz:'Scan code to download'
    },
    market:{
        dsj:'Big data mining',
        wj:'Frontier blockchain',
        xhjy:'Spot trading',
        qhjy:'Futures trade',
        ss:'Search the currency you care about',
        jyd:'Transaction pair',
        zxj:'Latest price',
        zdf:'Up and down range',
        zgj:'Maximum price',
        zdj:'Minimum price',
        cjl:'Turnover',
        cz:'Operation',
        jy:'Transaction'
    },
    transaction:{
      kpj:'Opening price',
        ss:'Search the currency you care about',
        bz:'Currency',
        jg:'Price',
        zdf:'Up and down range',
        zxcj:'Latest transaction',
        sl:'Quantity',
        sj:'Time',
        zgj:'Maximum price',
        zdj:'Minimum price',
        cjl:'Turnover',
        cje:'Turnover',
        xhjy:'Spot trading',
        qhjy:'Futures trade',
        ky:'Available',
        cb:'Recharge money',
        mrj:'Buying price',
        mrl:'Purchase volume',
        mcj:'Selling price',
        mcl:'Sales volume',
        mr:'Purchase',
        mc:'Sell out',
        jye:'A turnover',
        dqwt:'Current delegation',
        lswt:'Historical entrustment',
        zcgl:'Asset management',
        cx:'Revoke',
        ywc:'Completed',
        ycx:'Rescinded',
        xj:'Present price',
        zs:'Least',
        ms:'S',
        zc:'Assets',
        dj:'Frozen',
        yk:'Profit and loss',
        mz:'Buy up',
        md:'Buy down',
        djs:'Opening countdown',
        jyd:'Transaction pair',
        lx:'Type',
        fx:'Direction',
        ddsl:'Order quantity',
        ycjsl:'Number of transactions',
        je:'Amount of money',
        cz:'Operation',
        ze:'Total',
        kyye:'Available balance',
        djse:'Frozen amount',
        kssj:'Start time',
        ksjg:'Start price',
        jssj:'End time',
        jsjg:'End price',
        yjyl:'Estimated profit',
        mrsl:'Purchase quantity',
        bdyl:'Profit from this order',
        pk:'Handicap',
        wtdd:'Entrusted order',
        fz:'Min',
        xs:'H',
        ri:'Day',
        z:'Week',
        yue:'Month'
    },
    recharge:{
        cb:'Deposit',
        tit:'Select currency and copy wallet address',
        tit2:'Select the currency and network you want to recharge, and copy the wallet address on this page',
        tit3:'Cash withdrawal to wallet address',
        tit4:'Paste the copied wallet address on other exchange platforms and submit the withdrawal application',
        tit5:'Transfer confirmation',
        tit6:'Wait for the block network to confirm the transfer',
        tit7:'Deposit successful',
        tit8:'Block network confirmation will complete the transfer to your coinfam wallet address',
        xx:'Currency information',
        ze:'Total',
        kyye:'Available balance',
        djye:'Freeze balance',
        kcz:'Available deposit networks',
        ts:'Please ensure that the currency network you have selected on other exchange platforms is consistent with the currency network you have selected on this page.',
        czdz:'Deposit address',
        dz:'Address',
        wxts:'Reminder',
        wxts2:'Please do not recharge any non-',
        wxts3:'Assets, otherwise the assets will not be retrieved.',
        copy:'Copy succeeded'
    },
    withdraw:{
        tx:'Withdrawal',
        tx2:'Select currency and confirm withdrawal address',
        tx3:'Select the currency and network you want to recharge, and paste the copied withdrawal address on this page',
        tx4:'Transfer confirmation',
        tx5:'Wait for the block network to confirm your transfer',
        tx6:'Withdrawal succeeded',
        tx7:'Block network confirmation will complete the transfer to the withdrawal address for you',
        xx:'Currency information',
        ze:'total',
        kyye:'Available balance',
        djye:'Freeze balance',
        txdz:'Cash withdrawal address',
        srdz:'Please enter the withdrawal network',
        txwl:'Cash withdrawal network',
        txsl:'Withdrawal quantity',
        zx:'Minimum single withdrawal quantity:',
        qb:'Whole',
        sxf:'Service Charge',
        sjdz:'Actual receipt',
        jyma:'Transaction password',
        qsr:'Please enter the transaction password',
        szma:'Set Password',
        wxts:'Reminder',
        wxts2:'Please carefully check the currency and withdrawal address. After submission, it cannot be withdrawn. The withdrawal review time is 9:00-18:00 on working days. ',
        tj:'Submit'
    },
    down:{
        tit:'Digital assets',
        tit2:'One-stop',
        tit3:'Trading platform',
        zd:'Multi-platform terminal transaction anytime, anywhere',
        xz:'Scan code to download',
    },
    assets:{
        tit:'My assets',
        zh:' Total assets converted',
        jl:'Asset records',
        zczh:'Asset account',
        qb:'Wallet',
        bz:'Currency',
        kyye:'Available balance',
        djse:'Frozen amount',
        gz:'Valuation',
        cz:'Operation',
        cb:'Charge',
        tb:'Carry',
        qjy:'Trade',
        sj:'Time',
        czse:'Recharge amount',
        dz:'Block transaction address',
        zt:'State',
        ywc:'Completed',
        czjl:'Recharge record',
        txjl:'Cash withdrawal record',
        tbsl:'Withdrawal quantity',
        tbdz:'Cash withdrawal address',
        sxf:'Service Charge',
        jsse:'Settlement amount',
    },
    order:{
       bbdd:'Order in currency',
       qhdd:'Futures orders',
       dqwt:'Current delegation',
       lswt:'Historical entrustment',
       jyd:'Transaction pair',
       cjsj:'Creation time',
       fx:'Direction',
       wtjg:'Commission price',
       ddsl:'Order quantity',
       ycjsl:'Number of transactions',
       wtje:'Entrusted amount',
       cz:'Operation',
       ywc:'Completed',
       yqx:'Canceled',
       cd:'Cancel the order',
       zt:'State',
       mr:'Buy',
       mc:'Sell',
       mz:'Buy up',
       md:'Buy down',
       kssj:'Start time',
       ksjg:'Start price',
       jssj:'End time',
       jsjg:'End price',
       yjyl:'Estimated profit',
       kpdjs:'Opening countdown',
       bdyl:'Profit from this order'
    },
    my:{
        grzx:'Personal Center',
        sfrz:'Identity authentication',
        aqzx:'Security Center',
        zh:'Account number',
        zcsj:'Registration time',
        zjdl:'Recent login records',
        sj:'Time',
        dd:'Place',
        ed:'Current withdrawal limit',
        wc:'Complete authentication to improve your security level and withdrawal limit',
        rz:'Real-name authentication',
        tged:"Increase the withdrawal limit, which can be increased to",
        qrz:'De-certification',
        qxz:'Please select',
        xm:'Full name',
        zjhm:'Identification Number',
        tjsh:'Submit for review',
        dj:'Current account risk level',
        g:'High',
        z:'In',
        d:'low',
        dlma:'Login password',
        dlma2:'Improving password strength helps improve your fund security',
        yxyz:'Mailbox verification',
        yxyz2:'You can bind a common mailbox for login and password retrieval',
        jyma:'Transaction password',
        jyma2:'You can set a transaction password to confirm the withdrawal',
        xg:'Modify',
        sz:'Set up',
        ts:'Prompt: Cash withdrawal is prohibited within 24 hours after modifying the login password',
        qsr1:'Please enter the old password',
        qsr2:'Please enter a new password',
        qsr3:'Please confirm the password again',
        qx:'Cancel',
        qd:'Determine',
        sryx:'Please enter a new email address',
        sryx2:'Please enter the new email verification code',
        sryx3:'Please enter the old mailbox verification code',
        szjyma:'Set transaction password',
        szjyma2:'Confirm transaction password',
        sryzm:'Verification Code',
        yzm:'Send out',
        cjrz:'Certification',
        xqyx:'Modify mailbox',
        xs:'Surname',
        mz:'Name',
      yrz: 'Certified',
      sczp: 'Please upload your ID photo',
      yrz1: 'To be reviewed',
      lyfk: 'Message feedback',
      lytext: 'Leave a message',
      hftext: 'feedback',
     }
}
