module.exports = {
	language: {
	    name: '德语'
	},

  common_msg:{
    success: "Operation success!",
    error: 'Der Vorgang ist fehlgeschlagen. Bitte versuchen Sie es später erneut.',
  },
  trade_spot:{
    msgBuyPrice: 'Bitte geben Sie den richtigen Kaufpreis ein!',
    msgBuyNum: 'Bitte geben Sie die richtige Einkaufsmenge ein!',
    msgSellPrice: 'Bitte geben Sie den richtigen Verkaufspreis ein!',
    msgSellNum: 'Bitte geben Sie die richtige Verkaufsmenge ein!',
    msgUstdNotEnough: "Not enough available USDT's!",
    msgSellNumNotEnough: 'Nicht genug verfügbar zum Verkauf!',
  },
  download_text:{
    android:'Android',
    iphone:'IPhone',
  },
	login: {
		title: 'Bitte einloggen',
        yhm:'Nutzername',
		username: 'einen Benutzernamen eingeben',
        ma:'Passwort',
        pwd:'Passwort eingeben',
        zc:'registrieren',
        wj:'Passwort vergessen',
        dl:'Anmelden',
        cz:'Bargeldabhebung ist nicht innerhalb von 24-Stunden nach dem Zurücksetzen des Passworts erlaubt',
        yxdz:'E-Mail-Adresse',
        srdz:'Geben Sie Ihre E-Mail-Nummer ein',
        yxyz:'Postfachüberprüfung',
        yzm:'Prüfcode',
        send:'senden',
        next:'nächster Schritt',
        czma:'Passwort zurücksetzen',
        xmm:'Neues Passwort',
        szxmm:'Neues Passwort festlegen',
        qrma:'Passwort bestätigen',
        zcqr:'Geben Sie das neue Passwort erneut ein',
        qd:'bestimmen',
        cj:'Erstellen Sie Ihr Konto',
        sz:'Passwort festlegen',
        yyzh:'Bestehendes Konto',
        qdl:'Zur Anmeldung gehen',
	},
    nav:{
        sy:'Startseite',
        sc:'Markt',
        bbjy:'Währungstransaktionen',
        qhjy:'Termingeschäfte',
        xz:'Download',
        cb:'Geld aufladen',
        tb:'Geld abheben',
        zc:'Aktiva',
        da:'Bestellung',
        dl:'Anmelden',
        reg:'registrieren',
        out:'Abmelden'
    },
    footer:{
       sq:'Gemeinschaft',
       zc:'Unterstützung',
       bzzx:'Help Center',
       hysm:'Auftragsbeschreibung',
       gywm:'Über uns',
       fvbz:'Tarifstandard',
       sbsq:'Währung beantragen',
       tk:'Klausel',
       flsm:'Rechtliche Erklärung',
       fxts:'Risikoerklärung',
       yszc:'Datenschutzerklärung',
       yhxy:'Nutzungsvereinbarung',
       lxwm:'Kontaktieren Sie uns',
       swhz:'Zusammenarbeit zwischen Unternehmen',
       kfyx:'Postfach des Kundenservice'
    },
    index:{
        qq:'Global',
        aq:'Maximum und sicherste',
        jys:'Virtueller Währungswechsel',
        zc:'Registriertes Konto',
        rbb:'Hot Coin Liste',
        xbb:'Neue Währungsliste',
        zfb:'Tägliche Erhöhungsliste',
        jyd:'Transaktionspaar',
        jg:'Preis',
        zdf:'Aufwärts- und Abwärtsbereich',
        hq:'Angebot',
        xq:'Details',
        ckgd:'Mehr sehen',
        xzwm:'Warum uns wählen',
        x1:'Gesamtfonds für Sicherheit und Rechtssystem',
        x2:'Es gewährleistet Ihre Vermögenssicherheit durch Zentralisierung und Dezentralisierung und ist die einzige Handelsplattform der Welt, die Hunderte von Millionen Yuan investiert, um "Sicherheit und Rechtsfonds" für Ihre Vermögenssicherheit bereitzustellen.',
        x3:'Schnelles Gebührensystem für Transaktionen',
        x4:'Die langfristige Technologieakkumulation bietet Ihnen ein effizientes und komfortables Echtzeit-Handelssystem. Ihr Geld kann auch jede Sekunde belastet und abgehoben werden.',
        x5:'Wie immer ist es langlebig und stabil',
        x6:'Nach neun Jahren des stabilen Betriebs haben wir viele branchenführende Technologien angesammelt und bieten Ihnen weiterhin stabile Dienstleistungen. Wir sind voller Vertrauen und streben danach!',
        x7:'Ihre vertrauenswürdige Plattform',
        x8:'Als eine der Handelsplattformen mit der längsten Geschichte und dem größten Handelsvolumen der Welt folgt sie strikt den Branchenregeln und nimmt nicht am Marktbetrieb teil. Offenheit, Transparenz, Gleichheit und Zusammenarbeit waren schon immer unsere Mission.',
        ys:'Unsere Vorteile',
        y1:'Sicherheit',
        y2:'Die branchenführende Sicherheitstechnologie und Finanzstabilität sorgen dafür, dass Ihre Vermögenswerte sicher sind.',
        y3:'kostenlos',
        y4:'Es kann Positionen blitzschnell öffnen und schließen, und es gibt keinen Allokationsmechanismus. Die Handelsgewinnmittel des Benutzers können 100% frei zur Verfügung stehen.',
        y5:'schnell',
        y6:'Effiziente und qualitativ hochwertige Währungsprojekte mit ausgezeichneter Liquidität.',
        cp:'Unsere Produkte',
        c1:'Spot-Handel',
        c2:'Mehr als 1000 Kryptowährungen für Sie zum besten Preis zu handeln',
        c3:'Termingeschäfte',
        c4:'Futures-Handel ist ein Finanzderivat, das relativ zum Spotmarkt ist',
        c5:'Vertragsgeschäft',
        c6:'Die Vertragstransaktion basiert auf dem Spot-Forward-Vertrag. Mit Zustimmung beider Parteien wird die Betragstransaktion mit der anderen Partei zu einem bestimmten Zeitpunkt durchgeführt.',
        pt:'Multi-Plattform Terminal Transaktion jederzeit und überall',
        smxz:'Scancode zum Herunterladen'
    },
    market:{
        dsj:'Big Data Mining',
        wj:'Grenzblockchain',
        xhjy:'Spot-Handel',
        qhjy:'Termingeschäfte',
        ss:'Suchen Sie die Währung, die Sie interessieren',
        jyd:'Transaktionspaar',
        zxj:'Neuester Preis',
        zdf:'Aufwärts- und Abwärtsbereich',
        zgj:'Höchstpreis',
        zdj:'Mindestpreis',
        cjl:'Umsatz',
        cz:'Betrieb',
        jy:'Transaktion'
    },
    transaction:{
      dsj:'Eröffnungspreis',
        ss:'Suchen Sie die Währung, die Sie interessieren',
        bz:'Währung',
        jg:'Preis',
        zdf:'Aufwärts- und Abwärtsbereich',
        zxcj:'Letzte Transaktion',
        sl:'Menge',
        sj:'Zeit',
        zgj:'Höchstpreis',
        zdj:'Mindestpreis',
        cjl:'Umsatz',
        cje:'Umsatz',
        xhjy:'Spot-Handel',
        qhjy:'Termingeschäfte',
        ky:'verfügbar',
        cb:'Geld aufladen',
        mrj:'Kaufpreis',
        mrl:'Kaufvolumen',
        mcj:'Verkaufspreis',
        mcl:'Verkaufsvolumen',
        mr:'Kauf',
        mc:'ausverkauft',
        jye:'ein Umsatz',
        dqwt:'Aktuelle Delegation',
        lswt:'Historisches Vertrauen',
        zcgl:'Vermögensverwaltung',
        cx:'widerrufen',
        ywc:'Abgeschlossen',
        ycx:'aufgehoben',
        xj:'gegenwärtiger Preis',
        zs:'am wenigsten',
        ms:'zweite',
        zc:'Aktiva',
        dj:'gefroren',
        yk:'Gewinn und Verlust',
        cb:'Geld aufladen',
        mz:'Kaufen',
        md:'Buy down',
        djs:'Countdown wird geöffnet',
        jyd:'Transaktionspaar',
        lx:'Typ',
        fx:'Richtung',
        ddsl:'Bestellmenge',
        ycjsl:'Anzahl der Transaktionen',
        je:'Geldbetrag',
        cz:'Betrieb',
        ze:'insgesamt',
        kyye:'Verfügbarer Saldo',
        djse:'Gefrorene Menge',
        kssj:'Startzeit',
        ksjg:'Startpreis',
        jssj:'Endzeit',
        jsjg:'Endpreis',
        yjyl:'Geschätzter Gewinn',
        mrsl:'Einkaufsmenge',
        bdyl:'Profitieren Sie von diesem Auftrag',
        pk:'Behinderung',
        wtdd:'Auftragserteilung',
        fz:'Minute',
        xs:'Stunde',
        ri:'Tag',
        z:'Woche',
        yue:'Monat'
    },
    recharge:{
        cb:'Geld aufladen',
        tit:'Währung auswählen und Wallet-Adresse kopieren',
        tit2:'Wählen Sie die Währung und das Netzwerk aus, das Sie aufladen möchten, und kopieren Sie die Wallet-Adresse auf dieser Seite',
        tit3:'Bargeldabhebung an Wallet-Adresse',
        tit4:'在Fügen Sie die kopierte Wallet-Adresse auf anderen Exchange-Plattformen ein und senden Sie den Auszahlungsantrag',
        tit5:'Überweisungsbestätigung',
        tit6:'Warten Sie, bis das Blocknetzwerk die Übertragung bestätigt hat',
        tit7:'Überweisungsbestätigung',
        tit8:'Warten Sie, bis das Blocknetzwerk die Übertragung bestätigt hat',
        xx:'Währungsinformationen',
        ze:'insgesamt',
        kyye:'Verfügbarer Saldo',
        djye:'Gleichgewicht einfrieren',
        kcz:'Verfügbare Ladenetze',
        ts:'Bitte stellen Sie sicher, dass das Währungsnetzwerk, das Sie auf anderen Wechselplattformen ausgewählt haben, mit dem Währungsnetzwerk übereinstimmt, das Sie auf dieser Seite ausgewählt haben.',
        czdz:'Aufladeadresse',
        dz:'Adresse',
        wxts:'Erinnerung',
        wxts2:'Bitte laden Sie keine Nicht-',
        wxts3:'Assets, andernfalls werden die Assets nicht abgerufen.',
        copy:'Kopieren erfolgreich'
    },
    withdraw:{
        tx:'Bargeldabhebung',
        tx2:'Währung auswählen und Auszahlungsadresse bestätigen',
        tx3:'Wählen Sie die Währung und das Netzwerk aus, das Sie aufladen möchten, und fügen Sie die kopierte Auszahlungsadresse auf dieser Seite ein.',
        tx4:'Überweisungsbestätigung',
        tx5:'Warten Sie, bis das Blocknetzwerk Ihre Übertragung bestätigt hat',
        tx6:'Auszug erfolgreich',
        tx7:'Die Bestätigung des Blocknetzwerks schließt die Überweisung an die Auszahlungsadresse für Sie ab',
        xx:'Währungsinformationen',
        ze:'insgesamt',
        kyye:'Verfügbarer Saldo',
        djye:'Gleichgewicht einfrieren',
        txdz:'Adresse für Bargeldabhebung',
        srdz:'Bitte betreten Sie das Auszahlungsnetz',
        txwl:'Bargeldabhebungsnetz',
        txsl:'Entnahmemenge',
        zx:'Mindestentnahmemenge:',
        qb:'ganz',
        sxf:'Servicegebühr',
        sjdz:'Tatsächlicher Eingang',
        jyma:'Transaktionskennwort',
        qsr:'Bitte geben Sie das Transaktionspasswort ein',
        szma:'Passwort festlegen',
        wxts:'Erinnerung',
        wxts2:'Bitte überprüfen Sie sorgfältig die Währung und die Auszahlungsadresse. Nach der Übermittlung kann die Auszahlung nicht abgehoben werden. Die Überprüfungszeit für Auszahlungen beträgt 9:00-18:00 an Werktagen. ',
        tj:'Absenden'
    },
    down:{
        tit:'Digitale Assets',
        tit2:'One-Stop',
        tit3:'Handelsplattform',
        zd:'Multi-Plattform Terminal Transaktion jederzeit und überall',
        xz:'Scancode zum Herunterladen',
    },
    assets:{
        tit:'Mein Vermögen',
        zh:' Gesamte umgewandelte Vermögenswerte',
        jl:'Asset Records',
        zczh:'Vermögenskonto',
        qb:'Brieftasche',
        bz:'Währung',
        kyye:'Verfügbarer Saldo',
        djse:'Gefrorene Menge',
        gz:'Bewertung',
        cz:'Betrieb',
        cb:'Geld aufladen',
        tb:'Geld abheben',
        qjy:'Zum Handel',
        sj:'Zeit',
        czse:'Aufladungsbetrag',
        dz:'Transaktionsadresse sperren',
        zt:'Zustand',
        ywc:'Abgeschlossen',
        czjl:'Aufladungsprotokoll',
        txjl:'Aufzeichnung über Bargeldabhebungen',
        tbsl:'Entnahmemenge',
        tbdz:'Adresse für Bargeldabhebung',
        sxf:'Servicegebühr',
        jsse:'Abrechnungsbetrag',
        zt:'Zustand'
    },
    order:{
       bbdd:'Auftrag in Währung',
       qhdd:'Terminaufträge',
       dqwt:'Aktuelle Delegation',
       lswt:'Historisches Vertrauen',
       jyd:'Transaktionspaar',
       cjsj:'Erstellungszeit',
       fx:'Richtung',
       wtjg:'Kommissionspreis',
       ddsl:'Bestellmenge',
       ycjsl:'Anzahl der Transaktionen',
       wtje:'Zuverlässiger Betrag',
       cz:'Betrieb',
       jyd:'Transaktionspaar',
       ywc:'Abgeschlossen',
       yqx:'Abgebrochen',
       cd:'Bestellung stornieren',
       zt:'Zustand',
       mr:'kaufen',
       mc:'verkaufen',
       mz:'Kaufen',
       md:'Buy down',
       kssj:'Startzeit',
       ksjg:'Startpreis',
       jssj:'Endzeit',
       jsjg:'Endpreis',
       yjyl:'Geschätzter Gewinn',
       kpdjs:'Countdown wird geöffnet',
       bdyl:'Profitieren Sie von diesem Auftrag'
    },
    my:{
      sczp: 'Bitte laden Sie Ihr Ausweisfoto hoch',
       grzx:'Persönliches Zentrum',
       sfrz:'Identitätsauthentifizierung',
       aqzx:'Sicherheitscenter',
       zh:'Kontonummer',
       zcsj:'Anmeldezeit',
       zjdl:'Neueste Anmeldedaten',
       sj:'Zeit',
       dd:'Ort',
       ed:'Aktuelle Auszahlungsgrenze',
       wc:'Vollständige Authentifizierung, um Ihr Sicherheitsniveau und Ihr Auszahlungslimit zu verbessern',
       rz:'Echtname-Authentifizierung',
       tged:"Erhöhen Sie das Auszahlungslimit, das erhöht werden kann auf",
       qrz:'Entzertifizierung',
       qxz:'Bitte wählen',
       xm:'vollständiger Name',
       zjhm:'Identifikationsnummer',
       tjsh:'Zur Überprüfung einreichen',
       dj:'Höhe des Leistungsbilanzrisikos',
       g:'hoch',
       z:'in',
       d:'niedrig',
       dlma:'Login Passwort',
       dlma2:'Die Verbesserung der Passwortstärke hilft, die Sicherheit Ihres Fonds zu verbessern',
       yxyz:'Postfachüberprüfung',
       yxyz2:'Sie können ein gemeinsames Postfach für Login und Passwort-Abruf binden',
       jyma:'Transaktionskennwort',
       jyma2:'Sie können ein Transaktionskennwort festlegen, um die Auszahlung zu bestätigen',
       xg:'modifizieren',
       sz:'einrichten',
       ts:'Aufforderung: Bargeldabhebung ist innerhalb von 24-Stunden nach Änderung des Login-Passworts verboten',
       qsr1:'Bitte geben Sie das alte Passwort ein',
       qsr2:'Bitte geben Sie ein neues Passwort ein',
       qsr3:'Bitte bestätigen Sie das Passwort erneut',
       qx:'Abbrechen',
       qd:'bestimmen',
       sryx:'Bitte geben Sie eine neue E-Mail-Adresse ein',
       sryx2:'Bitte geben Sie den neuen E-Mail-Bestätigungscode ein',
       sryx3:'Bitte geben Sie den alten Postfach-Verifizierungscode ein',
       szjyma:'Transaktionskennwort festlegen',
       szjyma2:'Transaktionskennwort bestätigen',
       sryzm:'Prüfcode',
       yzm:'senden',
       cjrz:'Primärzertifizierung',
       xqyx:'Postfach ändern',
       xs:'Nachname',
       mz:'Name',
      yrz: 'Beglaubigt und beglaubigt.',
      yrz1: 'Ich bin offen.',
      lyfk: 'Feedback zu Nachrichten',
      lytext: 'Nachricht für mich.',
      hftext: 'Feedback.',
    }
}
