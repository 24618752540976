module.exports = {
	language: {
	    name: '日语'
	},

  common_msg:{
    success: '操作成功！',
    error: "操作に失敗しました。後で再試行してください。",
  },
  trade_spot:{
    msgBuyPrice: "正しい購入価格を入力してください！",
    msgBuyNum: "正しい購入数を入力してください！",
    msgSellPrice: '正しい販売価格を入力してください！',
    msgSellNum: '正しい販売数量を入力してください！',
    msgUstdNotEnough: "利用できるUSDTが不足している！",
    msgSellNumNotEnough: "売れる数が足りない！",
  },
  download_text:{
    android:'アンドロイド',
    iphone:'アップル',
  },
	login: {
		title: 'ログインしてください',
        yhm:'ユーザー名',
		username: 'ユーザー名の入力',
        ma:'パスワード',
        pwd:'パスワードを入力',
        zc:'登録',
        wj:'パスワードを忘れる',
        dl:'ログイン＃ログイン＃',
        cz:'パスワードをリセットしてから24時間以内の現金化は許可されていません',
        yxdz:'メールアドレス',
        srdz:'メールボックス番号を入力',
        yxyz:'メールボックスの検証',
        yzm:'認証コード',
        send:'認証コードの送信',
        next:'次のステップ',
        czma:'パスワードをリセット',
        xmm:'新しいパスワード',
        szxmm:'新しいパスワードの設定',
        qrma:'パスワードの確認',
        zcqr:'新しいパスワードを再入力',
        qd:'を選択して、',
        cj:'アカウントの作成',
        sz:'パスワードの設定',
        yyzh:'既存のアカウント',
        qdl:'ログインに行く',
	},
    nav:{
        sy:'トップページ',
        sc:'市場',
        bbjy:'貨幣取引',
        qhjy:'先物取引',
        xz:'ダウンロード',
        cb:'貨幣を充填する',
        tb:'貨幣をつりあげる',
        zc:'資産',
        da:'オーダー',
        dl:'ログイン＃ログイン＃',
        reg:'登録',
        out:'ログオンの終了'
    },
    footer:{
       sq:'コミュニティ',
       zc:'サポート',
       bzzx:'ヘルプセンター',
       hysm:'契約の説明',
       gywm:'私たちについて',
       fvbz:'レート基準',
       sbsq:'貨幣上納申請',
       tk:'条件',
       flsm:'法的注意事項',
       fxts:'リスク提示',
       yszc:'プライバシーポリシー',
       yhxy:'ユーザプロトコル',
       lxwm:'連絡先',
       swhz:'ビジネス提携',
       kfyx:'カスタマーサービスメール'
    },
    index:{
        qq:'グローバル',
        aq:'最大で最も安全',
        jys:'仮想通貨取引所',
        zc:'アカウントの登録',
        rbb:'熱貨幣ランキング',
        xbb:'新札ランキング',
        zfb:'1日の上昇幅ランキング',
        jyd:'取引ペア',
        jg:'価格',
        zdf:'上昇幅',
        hq:'相場',
        xq:'詳細',
        ckgd:'詳細を表示',
        xzwm:'なぜ私たちを選んだのか',
        x1:'安全と法制基金の全方位保障',
        x2:'中心化と脱中心化の2つの手段を通じてあなたの資産の安全を保障し、世界で唯一、数億元の資金を投入してあなたの資産の安全に「安全と法制基金」を提供する取引プラットフォームです。',
        x3:'迅速なトランザクションチャージシステム',
        x4:'長い時間をかけて技術が蓄積され、効率的で便利なリアルタイム取引システムを提供しています。あなたの資金の出入りも秒単位で提出することができます。',
        x5:'これまでと変わらず安定している',
        x6:'9年間の安定した運営、多くの業界をリードする技術を蓄積し、引き続きあなたに安定したサービスを提供して、私たちは自信に満ちて、そして努力して奮闘します！',
        x7:'信頼できるプラットフォーム',
        x8:'世界で最も歴史が長く、取引量が最も多い取引プラットフォームの1つとして、業界の規則に厳格に従い、市場の運営に参加しない。公開、透明、平等、協力は常に私たちが堅持する使命です。',
        ys:'デルの強み',
        y1:'セキュリティ',
        y2:'業界をリードするセキュリティ技術と財務安定性により、資産の安全性が保証されます。',
        y3:'フリー',
        y4:'稲妻式の開倉、平倉操作を行うことができ、さらに割り当てメカニズムがなく、ユーザーの取引利益資金は100%自由に支配することができる。',
        y5:'すばやい',
        y6:'効率的で新しい高品質で優れた流動性を持つ通貨プロジェクト。',
        cp:'デルの製品',
        c1:'現物取引',
        c2:'最適な価格で取引できる1000種類以上の暗号化通貨',
        c3:'先物取引',
        c4:'先物取引は現物市場に対する取引である金融派生品である',
        c5:'契約取引',
        c6:'契約取引は現物の先物契約をもとに、双方が同意した場合、時間を指定して他方と金額取引を行う。',
        pt:'いつでもどこでもマルチプラットフォーム端末取引',
        smxz:'スキャンコードのダウンロード'
    },
    market:{
        dsj:'ビッグデータマイニング',
        wj:'最前線ブロックチェーン',
        xhjy:'現物取引',
        qhjy:'先物取引',
        ss:'関心のある通貨の検索',
        jyd:'取引ペア',
        zxj:'最新価格',
        zdf:'上昇幅',
        zgj:'最高価格',
        zdj:'最低価格',
        cjl:'出来高',
        cz:'操作',
        jy:'取り引き'
    },
    transaction:{
      kpj:"寄り付き相場",
        ss:'関心のある通貨の検索',
        bz:'通貨＃ツウカ＃',
        jg:'価格',
        zdf:'上昇幅',
        zxcj:'最新の取引',
        sl:'数量',
        sj:'時間',
        zgj:'最高価格',
        zdj:'最低価格',
        cjl:'出来高',
        cje:'出来高',
        xhjy:'現物取引',
        qhjy:'先物取引',
        ky:'使用可能',
        cb:'貨幣を充填する',
        mrj:'買い取り価格',
        mrl:'購入量',
        mcj:'売値',
        mcl:'売れ行き',
        mr:'購入する',
        mc:'売り出す',
        jye:'取引高',
        dqwt:'現在の委任',
        lswt:'履歴の委任',
        zcgl:'資産管理',
        cx:'元に戻す',
        ywc:'完了',
        ycx:'取り消し済み',
        xj:'げんか',
        zs:'最小',
        ms:'秒',
        zc:'資産',
        dj:'とうけつ',
        yk:'に利益を与える',
        cb:'貨幣を充填する',
        mz:'買い増し',
        md:'買いが落ちる',
        djs:'オープンカウントダウン',
        jyd:'取引ペア',
        lx:'を選択してオプションを設定します。',
        fx:'方向',
        ddsl:'オーダー数量',
        ycjsl:'成約済み数量',
        je:'金額',
        cz:'操作',
        ze:'合計',
        kyye:'使用可能残高',
        djse:'凍結額',
        kssj:'開始時間',
        ksjg:'開始価格',
        jssj:'終了時間',
        jsjg:'終了価格',
        yjyl:'予想利益',
        mrsl:'購入数量',
        bdyl:'本明細書の利益',
        pk:'コイル口',
        wtdd:'発注',
        fz:'分',
        xs:'時間',
        ri:'日',
        z:'周',
        yue:'月'
    },
    recharge:{
        cb:'貨幣を充填する',
        tit:'通貨を選択してウォレット・アドレスをコピー',
        tit2:'チャージする通貨とネットワークを選択し、このページでウォレット・アドレスをコピーします',
        tit3:'ウォレットアドレスへの現金化',
        tit4:'他の取引所プラットフォームにコピーされたウォレットアドレスを貼り付け、現金化申請を提出する',
        tit5:'振替確認',
        tit6:'ブロックネットワーク確認振替待ち',
        tit7:'ウォレットアドレスへの現金化',
        tit8:'ブロックネットワークの確認、ウォレットアドレスへの振り込み完了',
        xx:'通貨情報',
        ze:'合計',
        kyye:'使用可能残高',
        djye:'残高の凍結',
        kcz:'利用可能なチャージネットワーク',
        ts:'他の取引所プラットフォームで選択した通貨ネットワークが、このページで選択した通貨ネットワークと一致していることを確認してください。',
        czdz:'チャージアドレス',
        dz:'アドレス',
        wxts:'暖かいヒント',
        wxts2:'上記アドレスに非をチャージしないでください',
        wxts3:'資産、さもなければ資産は回収できません。',
        copy:'コピー成功'
    },
    withdraw:{
        tx:'現金化',
        tx2:'通貨を選択し、現金化先を確認する',
        tx3:'チャージする通貨とネットワークを選択し、このページにコピーしたキャッシュアドレスを貼り付けます',
        tx4:'振替確認',
        tx5:'ブロックネットワークがあなたの振替を確認するのを待つ',
        tx6:'引き出し成功',
        tx7:'ブロックネットワークの確認により、キャッシュアドレスへの振り込みが完了します',
        xx:'通貨情報',
        ze:'合計',
        kyye:'使用可能残高',
        djye:'残高の凍結',
        txdz:'キャッシュアドレス',
        srdz:'キャッシュネットワークを入力してください',
        txwl:'キャッシュネットワーク',
        txsl:'引き出し数量',
        zx:'最小シングルペン引き出し数量：',
        qb:'すべて',
        sxf:'手数料',
        sjdz:'実際の入金',
        jyma:'トランザクションパスワード',
        qsr:'取引パスワードを入力してください',
        szma:'パスワードの設定',
        wxts:'暖かいヒント',
        wxts2:'貨幣種類と貨幣引出先を真剣にチェックしてください。提出後は取り下げることができません。貨幣引出審査時間は：平日9：00-18：00です。 ',
        tj:'参加する'
    },
    down:{
        tit:'デジタル資産',
        tit2:'ワンストップ',
        tit3:'取引プラットフォーム',
        zd:'いつでもどこでもマルチプラットフォーム端末取引',
        xz:'スキャンコードのダウンロード',
    },
    assets:{
        tit:'マイ資産',
        zh:' 総資産換算',
        jl:'資産レコード',
        zczh:'資産アカウント',
        qb:'財布',
        bz:'通貨＃ツウカ＃',
        kyye:'使用可能残高',
        djse:'凍結額',
        gz:'評価',
        cz:'操作',
        cb:'貨幣を充填する',
        tb:'貨幣をつりあげる',
        qjy:'取引に行く',
        sj:'時間',
        czse:'チャージ額',
        dz:'ブロックトランザクションアドレス',
        zt:'ステータス',
        ywc:'完了',
        czjl:'チャージレコード',
        txjl:'キャッシュレコード',
        tbsl:'外貨引出数量',
        tbdz:'外貨引出先住所',
        sxf:'手数料',
        jsse:'決済額',
        zt:'ステータス'
    },
    order:{
       bbdd:'通貨受注',
       qhdd:'期货订单',
       dqwt:'現在の委任',
       lswt:'履歴の委任',
       jyd:'取引ペア',
       cjsj:'作成時間',
       fx:'方向',
       wtjg:'委託価格',
       ddsl:'オーダー数量',
       ycjsl:'成約済み数量',
       wtje:'委任金額',
       cz:'操作',
       jyd:'取引ペア',
       ywc:'完了',
       yqx:'キャンセル済み',
       cd:'請求を取り消す',
       zt:'ステータス',
       mr:'買う',
       mc:'売る',
       mz:'買い増し',
       md:'買いが落ちる',
       kssj:'開始時間',
       ksjg:'開始価格',
       jssj:'終了時間',
       jsjg:'終了価格',
       yjyl:'予想利益',
       kpdjs:'オープンカウントダウン',
       bdyl:'本明細書の利益'
    },
    my:{
       grzx:'パーソナルセンター',
       sfrz:'資格認定',
       aqzx:'セキュリティセンター',
       zh:'アカウント',
       zcsj:'登録時間',
       zjdl:'最近のログイン記録',
       sj:'時間',
       dd:'場所',
       ed:'現在の引き出し額',
       wc:'セキュリティレベルと外貨準備高を向上させるための認証の完了',
       rz:'実名認証',
       tged:"引き上げ限度額を引き上げ、24時間限度額を",
       qrz:'認証の解除',
       qxz:'選択してください',
       xm:'名前',
       zjhm:'証明書番号',
       tjsh:'レビューの送信',
       dj:'現在のアカウントリスクレベル',
       g:'高い',
       z:'中',
       d:'低い',
       dlma:'ログインパスワード',
       dlma2:'パスワードの強度を高めることで、資金セキュリティを向上させることができます',
       yxyz:'メールボックスの検証',
       yxyz2:'ログイン、パスワードの復元に使用する一般的なメールボックスをバインドできます',
       jyma:'トランザクションパスワード',
       jyma2:'通貨引出時の確認用に取引パスワードを設定できます',
       xg:'変更',
       sz:'設定＃セッテイ＃',
       ts:'ヒント：ログインパスワードを変更してから24時間以内の現金引き出し禁止',
       qsr1:'古いパスワードを入力してください',
       qsr2:'新しいパスワードを入力してください',
       qsr3:'パスワードを再確認してください',
       qx:'キャンセル',
       qd:'を選択して、',
       sryx:'新しいメールアドレスを入力してください',
       sryx2:'新しいメールボックス認証コードを入力してください',
       sryx3:'古いメールボックスの認証コードを入力してください',
       szjyma:'取引パスワードの設定',
       szjyma2:'取引パスワードの確認',
       sryzm:'認証コード',
       yzm:'認証コード',
       cjrz:'初級認定',
       xqyx:'メールボックスの変更',
       xs:'名前',
       mz:'の名前をあげる',
      yrz: '認証済みです',
      sczp: '証明書の写真をアップロードしてください',
      yrz1: '審査待ちです',
      lyfk: 'メッセージフィードバック',
      lytext: '伝言です',
      hftext: 'フィードバックです',
    }
}
