module.exports = {
	language: {
	    name: '繁体'
	},

  common_msg:{
    success:'操作成功！',
    error:'操作失敗，請稍後重試。',
  },
  trade_spot:{
    msgBuyPrice:'請輸入正確的買入價！',
    msgBuyNum:'請輸入正確的買入數量！',
    msgSellPrice:'請輸入正確的賣出價！',
    msgSellNum:'請輸入正確的賣出數量！',
    msgUstdNotEnough:'可用USDT不足！',
    msgSellNumNotEnough:'可賣出數量不足！',
  },
  download_text:{
    android:'安卓',
    iphone:'蘋果',
  },

	login: {
		title: '歡迎登錄',
        yhm:'用戶名',
		username: '輸入用戶名',
        ma:'密碼',
        pwd:'輸入密碼',
        zc:'註冊',
        wj:'忘記密碼',
        dl:'登錄',
        cz:'重置密碼後24小時內不允許提現',
        yxdz:'郵箱地址',
        srdz:'輸入您的郵箱號碼',
        yxyz:'郵箱驗證',
        yzm:'驗證碼',
        send:'發送驗證碼',
        next:'下一步',
        czma:'重置密碼',
        xmm:'新密碼',
        szxmm:'設置新密碼',
        qrma:'確認密碼',
        zcqr:'再次輸入新密碼',
        qd:'確定',
        cj:'創建您的賬號',
        sz:'設置密碼',
        yyzh:'已有賬號',
        qdl:'去登錄',
	},
    nav:{
        sy:'首頁',
        sc:'市場',
        bbjy:'幣幣交易',
        qhjy:'期貨交易',
        xz:'下載',
        cb:'充幣',
        tb:'提幣',
        zc:'資產',
        da:'訂單',
        dl:'登錄',
        reg:'註冊',
        out:'退出登錄'
    },
    footer:{
       sq:'社區',
       zc:'支持',
       bzzx:'幫助中心',
       hysm:'合約說明',
       gywm:'關於我們',
       fvbz:'費率標準',
       sbsq:'上幣申請',
       tk:'條款',
       flsm:'法律聲明',
       fxts:'風險提示',
       yszc:'隱私政策',
       yhxy:'用戶協議',
       lxwm:'聯系我們',
       swhz:'商務合作',
       kfyx:'客服郵箱'
    },
    index:{
        qq:'全球',
        aq:'最大最安全',
        jys:'虛擬貨幣交易所',
        zc:'註冊賬號',
        rbb:'熱幣榜',
        xbb:'新幣榜',
        zfb:'日漲幅榜',
        jyd:'交易對',
        jg:'價格',
        zdf:'漲跌幅',
        hq:'行情',
        xq:'詳情',
        ckgd:'查看更多',
        xzwm:'為什麽選擇我們',
        x1:'安全與法製基金全方位保障',
        x2:'通過中心化和去中心化雙重手段保障您的資產安全，並且是全球唯一一個投入數億元資金為您的資產安全提供「安全與法製基金」的交易平臺。',
        x3:'快捷的交易充提系統',
        x4:'經久的技術積澱,為您提供了高效便捷的實時交易系統。您的資金出入亦可秒充秒提。',
        x5:'一如既往 經久穩定',
        x6:'9年的穩定運營, 積累了眾多行業領先的技術,繼續為您提供穩定的服務,我們充滿信心,並努力為之奮鬥!',
        x7:'您可信賴的平臺',
        x8:'作為全球歷史最長和交易量最大的交易平臺之一, 嚴格遵循行業規則,不參與市場運作。公開、透明、平等、合作一直是我們堅持的使命。',
        ys:'我們的優勢',
        y1:'安全',
        y2:'行業領先安全技術和財務穩定性，確保您的資產安全有保障。',
        y3:'自由',
        y4:'可進行閃電式的開倉、平倉操作，更無分攤機製，用戶交易盈利資金可100%自由支配。',
        y5:'快捷',
        y6:'高效上新優質且擁有絕佳流動性的幣種項目。',
        cp:'我們的產品',
        c1:'現貨交易',
        c2:'1000多種加密貨幣供您以最優價格進行交易',
        c3:'期貨交易',
        c4:'期貨交易是一種金融衍生品，它是相對於現貨市場的交易',
        c5:'合約交易',
        c6:'合約交易是以現貨的遠期合約為基礎，在雙方都同意的情況下,指定時間與另一方進行金額交易。',
        pt:'隨時隨地多平臺終端交易',
        smxz:'掃碼下載'
    },
    market:{
        dsj:'大數據挖掘',
        wj:'前沿區塊鏈',
        xhjy:'現貨交易',
        qhjy:'期貨交易',
        ss:'搜索您關心的幣種',
        jyd:'交易對',
        zxj:'最新價',
        zdf:'漲跌幅',
        zgj:'最高價',
        zdj:'最低價',
        cjl:'成交量',
        cz:'操作',
        jy:'交易'
    },
    transaction:{
        kpj:'開盤價',
        ss:'搜索您關心的幣種',
        bz:'幣種',
        jg:'價格',
        zdf:'漲跌幅',
        zxcj:'最新成交',
        sl:'數量',
        sj:'時間',
        zgj:'最高價',
        zdj:'最低價',
        cjl:'成交量',
        cje:'成交額',
        xhjy:'現貨交易',
        qhjy:'期貨交易',
        ky:'可用',
        cb:'充幣',
        mrj:'買入價',
        mrl:'買入量',
        mcj:'賣出價',
        mcl:'賣出量',
        mr:'買入',
        mc:'賣出',
        jye:'交易額',
        dqwt:'當前委托',
        lswt:'歷史委托',
        zcgl:'資產管理',
        cx:'撤銷',
        ywc:'已完成',
        ycx:'已撤銷',
        xj:'現價',
        zs:'最少',
        ms:'秒',
        zc:'資產',
        dj:'凍結',
        yk:'盈亏',
        cb:'充幣',
        mz:'買漲',
        md:'買跌',
        djs:'開盤倒計時',
        jyd:'交易對',
        lx:'類型',
        fx:'方向',
        ddsl:'訂單數量',
        ycjsl:'已成交數量',
        je:'金額',
        cz:'操作',
        ze:'總額',
        kyye:'可用余額',
        djse:'凍結數額',
        kssj:'開始時間',
        ksjg:'開始價格',
        jssj:'結束時間',
        jsjg:'結束價格',
        yjyl:'預計盈利',
        mrsl:'買入數量',
        bdyl:'本單盈利',
        pk:'盤口',
        wtdd:'委托訂單',
        fz:'分鐘',
        xs:'小時',
        ri:'日',
        z:'周',
        yue:'月'
    },
    recharge:{
        cb:'充幣',
        tit:'選擇幣種並復製錢包地址',
        tit2:'選擇您要充值的幣種及網絡，並在本頁復製錢包地址',
        tit3:'提現至錢包地址',
        tit4:'在其他交易所平臺上粘貼已有復製的錢包地址，並提交提現申請',
        tit5:'轉賬確認',
        tit6:'等待區塊網絡確認轉賬',
        tit7:'提現至錢包地址',
        tit8:'區塊網絡確認，將完成轉賬到您的錢包地址',
        xx:'幣種信息',
        ze:'總額',
        kyye:'可用余額',
        djye:'凍結余額',
        kcz:'可用的充值網絡',
        ts:'請確保您在其他交易所平臺選擇的幣種網絡與本頁已選的幣種網絡一致。',
        czdz:'充值地址',
        dz:'地址',
        wxts:'温馨提示',
        wxts2:'請勿向上述地址充值任何非',
        wxts3:'資產，否則資產將不可找回',
        copy:'復製成功'
    },
    withdraw:{
        tx:'提現',
        tx2:'選擇幣種並確認提幣地址',
        tx3:'選擇您要充值的幣種及網絡，並在本頁粘貼已復製的提幣地址',
        tx4:'轉賬確認',
        tx5:'等待區塊網絡確認您的轉賬',
        tx6:'提幣成功',
        tx7:'區塊網絡確認，將為您完成轉賬到提幣地址',
        xx:'幣種信息',
        ze:'總額',
        kyye:'可用余額',
        djye:'凍結余額',
        txdz:'提幣地址',
        srdz:'請輸入提幣地址',
        txwl:'提幣網絡',
        txsl:'提幣數量',
        zx:'最小單筆提幣數量：',
        qb:'全部',
        sxf:'手續費',
        sjdz:'實際到賬',
        jyma:'交易密碼',
        qsr:'請輸入交易密碼',
        szma:'設置密碼',
        wxts:'温馨提示',
        wxts2:'請認真核對幣種及提幣地址，提交後將無法撤回，提幣審核時間為：工作日9:00-18:00。 ',
        tj:'提交'
    },
    down:{
        tit:'數字資產',
        tit2:'一站式',
        tit3:'交易平臺',
        zd:'隨時隨地多平臺終端交易',
        xz:'掃碼下載',
    },
    assets:{
        tit:'我的資產',
        zh:' 總資產折合',
        jl:'資產記錄',
        zczh:'資產賬戶',
        qb:'錢包',
        bz:'幣種',
        kyye:'可用余額',
        djse:'凍結數額',
        gz:'估值',
        cz:'操作',
        cb:'充幣',
        tb:'提幣',
        qjy:'去交易',
        sj:'時間',
        czse:'充值數額',
        dz:'區塊交易地址',
        zt:'狀態',
        ywc:'已完成',
        czjl:'充值記錄',
        txjl:'提現記錄',
        tbsl:'提幣數量',
        tbdz:'提幣地址',
        sxf:'手續費',
        jsse:'結算數額',
        zt:'狀態'
    },
    order:{
       bbdd:'幣幣訂單',
       qhdd:'期貨訂單',
       dqwt:'當前委托',
       lswt:'歷史委托',
       jyd:'交易對',
       cjsj:'創建時間',
       fx:'方向',
       wtjg:'委托價格',
       ddsl:'訂單數量',
       ycjsl:'已成交數量',
       wtje:'委托金額',
       cz:'操作',
       jyd:'交易對',
       ywc:'已完成',
       yqx:'已取消',
       cd:'撤單',
       zt:'狀態',
       mr:'買',
       mc:'賣',
       mz:'買漲',
       md:'買跌',
       kssj:'開始時間',
       ksjg:'開始價格',
       jssj:'結束時間',
       jsjg:'結束價格',
       yjyl:'預計盈利',
       kpdjs:'開盤倒計時',
       bdyl:'本單盈利'
    },
    my:{
        grzx:'個人中心',
        sfrz:'身份認證',
        aqzx:'安全中心',
        zh:'賬號',
        zcsj:'註冊時間',
        zjdl:'最近登錄記錄',
        sj:'時間',
        dd:'地點',
        ed:'當前提幣額度',
        wc:'完成身份驗證以提升您的安全等級和提幣額度',
        rz:'實名認證',
        tged:"提高提幣額度，24小時限額可增加至",
        qrz:'去認證',
        qxz:'請選擇',
        xm:'姓名',
        zjhm:'證件號碼',
        tjsh:'提交審核',
        dj:'當前賬號風險等級',
        g:'高',
        z:'中',
        d:'低',
        dlma:'登錄密碼',
        dlma2:'提高密碼強度有助於提升您的資金安全',
        yxyz:'郵箱驗證',
        yxyz2:'您可以綁定一個常用郵箱，用於登錄、找回密碼',
        jyma:'交易密碼',
        jyma2:'您可以設置一個交易密碼，用於提幣時的確認',
        xg:'修改',
        sz:'設置',
        ts:'提示:修改登錄密碼後24小時內禁止提現',
        qsr1:'請輸入舊密碼',
        qsr2:'請輸入新密碼',
        qsr3:'請再次確認密碼',
        qx:'取消',
        qd:'確定',
        sryx:'請輸入新郵箱地址',
        sryx2:'請輸入新郵箱驗證碼',
        sryx3:'請輸入舊郵箱驗證碼',
        szjyma:'設置交易密碼',
        szjyma2:'確認交易密碼',
        sryzm:'請輸入郵箱驗證碼',
        yzm:'驗證碼',
        cjrz:'認證',
       xqyx:'修改郵箱',
       xs:'姓氏',
       mz:'名字',
      yrz: '已認證',
      sczp: '請上傳證件照片',
      yrz1: '待審覈',
      lyfk: '留言反饋',
      lytext: '留言',
      hftext: '反饋',
     }
}
