import axios from 'axios'
import { Loading, Message } from 'element-ui'

// create an axios instance
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})

// 请求拦截
service.interceptors.request.use(
  config => {
    if(config.isLoading){
      startLoading()
    }
    const token = sessionStorage.getItem('token')
    if(token) {
      config.headers.Authorization = token; //如果要求携带在请求头中
    }
    if (config.data === undefined) {
      config.data = {};
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => {
    if(response.config.isLoading){
      endLoading();
    }
    const res = response.data
    if(res.code === 0){
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }else
    if(res.code === 401){
      // Message({
      //   message: '登录信息过期，请重新登录',
      //   type: 'error',
      //   duration: 3 * 1000,
      //   onClose:()=>{
      //     window.location.href = '/#/'
      //   }
      // })
      window.location.href = '/#/login'
      localStorage.clear()
      // window.reload()
    }else if(res.code === 1){
      return res
    }
    return res
  },
  error => {
    Message({
      message: '系统错误',
      type: 'error',
      duration: 3 * 1000
    })
    endLoading();
    return Promise.reject(error)
  }
)

// 加载封装
let loading

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: 'Loading...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

// 关闭加载
function endLoading() {
  loading.close()
}

const request = (url, method = 'get', data,isLoading=false) => {
  const config = {}
  if(data===undefined){
    data={};
  }
  data.lang = sessionStorage.getItem('lang')
  let date = new Date();
  data.timezoneNum = date.getTimezoneOffset() / -60
  if(data){
    if (method === 'get') {
      config.params = data
    } else {
      config.data = data
    }
  }


  return service({
    url,
    method,
    isLoading,
    ...config
  })
}
export default request
