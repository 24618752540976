import request from '@/utils/request'

const url = 'api/deal/'

export const getIconPriceList = (data) => request(url+'icon-price','get',data,true)
export const getIconData = (data) => request(url+'icon-data','get',data,true)
export const getDealIconList = (data) => request(url+'deal-icon','get',data,true)
export const addDeal = (data) => request(url+'deal-add','get',data,true)
export const getDealList = (data) => request(url+'deal-list','get',data,true)
export const cancelDeal = (data) => request(url+'deal-cancel','get',data,true)
export const getDealItem = (data) => request(url+'deal-item','get',data,true)

