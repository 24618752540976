<template>
  <div class="content">
    <heads></heads>
    <div class="box-banner">
      <div class="box-banner-tit">{{ $t('index.qq') }}<span
        style="color:#19BFF8;">{{ $t('index.aq') }}</span>{{ $t('index.jys') }}
      </div>
      <div class="box-banner-reg" v-if="!token" @click="toReg()">{{ $t('index.zc') }}</div>
    </div>
    <div class="quotation-box">
      <div class="quotation-box-head">
        <div class="quotation-box-nav" style="margin-right:184px;" :class="[activeIndex==1?'quotation-active':'']"
             @click="btn(1)">
          <img class="quotation-box-img" src="../../assets/index/rbb.png" alt="">
          <div class="quotation-box-tit">{{ $t('index.rbb') }}</div>
        </div>
       <!-- <div class="quotation-box-nav" style="margin-right:184px;" :class="[activeIndex==2?'quotation-active':'']"
             @click="btn(2)">
          <img class="quotation-box-img" src="../../assets/index/xbb.png" alt="">
          <div class="quotation-box-tit">{{ $t('index.xbb') }}</div>
        </div> -->
        <div class="quotation-box-nav" :class="[activeIndex==3?'quotation-active':'']" @click="btn(3)">
          <img class="quotation-box-img" src="../../assets/index/rzf.png" alt="">
          <div class="quotation-box-tit">{{ $t('index.zfb') }}</div>
        </div>
      </div>
      <div class="quotation-list">
        <div class="quotation-list-tit">
          <div>{{ $t('index.jyd') }}</div>
          <div>{{ $t('index.jg') }}</div>
          <div>{{ $t('index.zdf') }}</div>
<!--          <div style="text-align: center;">{{ $t('index.hq') }}</div>-->
          <div style="text-align: right;"></div>
        </div>
      </div>
      <div v-if="activeIndex!==3">
        <div class="quotation-list" v-for="(item,index) in getData" :key="index" >
          <div class="quotation-list-tit">
            <div class="fs-16"><span style="color: #FFFFFF;">{{ item.coin_name }}</span> /USDT</div>
            <div style="color: #FFFFFF;">{{ (item.coin_price * 1).toFixed(item.coin_price>1000?2:6) }}</div>
            <div class="green" :class="{'kuang':item.change_num<0}">{{ item.change_num >= 0 ? '+' : '' }}{{ (item.change_num * 1).toFixed(2) }}%</div>
<!--            <div class="quotation-list-red">-->
<!--              <img style="width: 110px;height: 32px;" src="../../assets/index/green.png" alt="">-->
<!--            </div>-->
            <div class="intro-flex">
              <div class="intro" @click="toDel(item)">{{ $t('index.xq') }}</div>
            </div>

          </div>
        </div>
      </div>
      <div v-else>
        <div class="quotation-list" v-for="(item,index) in roseData"  >
          <div class="quotation-list-tit">
            <div class="fs-16"><span style="color: #FFFFFF;">{{ item.coin_name }}</span> /USDT  as</div>
            <div style="color: #FFFFFF;">{{ (item.coin_price * 1).toFixed(item.coin_price>1000?2:6) }}</div>
            <div class="green" :class="{'kuang':item.change_num<0}">{{ item.change_num >= 0 ? '+' : '' }}{{ (item.change_num * 1).toFixed(2) }}%</div>
<!--            <div class="quotation-list-red">-->
<!--              <img style="width: 110px;height: 32px;" src="../../assets/index/green.png" alt="">-->
<!--            </div>-->
            <div class="intro-flex">
              <div class="intro" @click="toDel(item)">{{ $t('index.xq') }}</div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="box-about">
      <div style="display: flex;">
        <div></div>
        <div class="box-about-tit">{{ $t('index.xzwm') }}</div>
      </div>
      <div class="box-about-flex">
        <div class="box-about-son">
          <img class="box-about-img" src="../../assets/index/dp.png" alt="">
          <div class="box-about-son-tit">{{ $t('index.x1') }}</div>
          <div class="box-about-son-word">{{ $t('index.x2') }}</div>
        </div>
        <div class="box-about-son">
          <img class="box-about-img" src="../../assets/index/ds.png" alt="">
          <div class="box-about-son-tit">{{ $t('index.x3') }}</div>
          <div class="box-about-son-word">{{ $t('index.x4') }}</div>
        </div>
        <div class="box-about-son">
          <img class="box-about-img" src="../../assets/index/nine.png" alt="">
          <div class="box-about-son-tit">{{ $t('index.x5') }}</div>
          <div class="box-about-son-word">{{ $t('index.x6') }}</div>
        </div>
        <div class="box-about-son">
          <img class="box-about-img" src="../../assets/index/sy.png" alt="">
          <div class="box-about-son-tit">{{ $t('index.x7') }}</div>
          <div class="box-about-son-word">{{ $t('index.x8') }}</div>
        </div>
      </div>

    </div>

    <div class="box-advantage">
      <div style="display: flex;">
        <div></div>
        <div class="box-about-tit">{{ $t('index.ys') }}</div>
      </div>
      <div class="box-advantage-flex">
        <div class="box-about-son">
          <img class="box-about-img2" src="../../assets/index/dp.png" alt="">
          <div class="box-about-son-tit2">{{ $t('index.y1') }}</div>
          <div class="box-about-son-word">{{ $t('index.y2') }}</div>
        </div>
        <div class="box-about-son">
          <img class="box-about-img2" src="../../assets/index/people.png" alt="">
          <div class="box-about-son-tit2">{{ $t('index.y3') }}</div>
          <div class="box-about-son-word">{{ $t('index.y4') }}</div>
        </div>
        <div class="box-about-son">
          <img class="box-about-img2" src="../../assets/index/hj.png" alt="">
          <div class="box-about-son-tit2">{{ $t('index.y5') }}</div>
          <div class="box-about-son-word">{{ $t('index.y6') }}</div>
        </div>
      </div>
    </div>
    <div class="box-about">
      <div style="display: flex;">
        <div></div>
        <div class="box-about-tit">{{ $t('index.cp') }}</div>
      </div>
      <div class="box-about-flex">
        <div class="box-about-son">
          <img class="box-about-img3" src="../../assets/index/xh.png" alt="">
          <div class="box-about-son-tit2">{{ $t('index.c1') }}</div>
          <div class="box-about-son-word">{{ $t('index.c2') }}</div>
        </div>
        <div class="box-about-son">
          <img class="box-about-img3" src="../../assets/index/qh.png" alt="">
          <div class="box-about-son-tit2">{{ $t('index.c3') }}</div>
          <div class="box-about-son-word">{{ $t('index.c4') }}</div>
        </div>
        <div class="box-about-son">
          <img class="box-about-img3" src="../../assets/index/hy.png" alt="">
          <div class="box-about-son-tit2">{{ $t('index.c5') }}</div>
          <div class="box-about-son-word">{{ $t('index.c6') }}</div>
        </div>
      </div>

    </div>
    <div class="box-advantage">
      <div style="display: flex;">
        <div></div>
        <div class="box-about-tit">{{ $t('index.pt') }}</div>
      </div>
      <div class="box-advantage-flex" style="display: flex;align-items: center;">
        <div class="box-about-son">
<!--          <img style="width: 262px;height: 262px;" :src="'../../assets/index/dh'+(lang==='zh-CN'?'':'-en')+'.png'" alt="">-->
          <img style="width: 262px;height: 262px;" v-if="lang==='zh-CN'" src="../../assets/index/dh.png" alt="">
          <img style="width: 262px;height: 262px;" v-else src="../../assets/index/dh-en.png" alt="">
        </div>
        <div class="box-about-son">
          <!-- <img class="box-about-goole" style="margin-bottom: 50px;" src="../../assets/index/gl.png" alt="">
          <img class="box-about-goole" src="../../assets/index/st.png" alt=""> -->
          <div class="box-android" style="margin-bottom: 50px;" v-show="false">
            <img class="box-android-img" src="@/assets/img/android.png" alt="">
            <div class="box-android-text">Android</div>
          </div>
          <div class="box-android" v-show="false">
            <img class="box-android-img" src="@/assets/img/iphone.png" alt="">
            <div class="box-android-text">IPhone</div>
          </div>
        </div>
        <div class="box-about-son">
          <div class="box-code">
            <div style="display: flex;align-items: center;">
              <!-- <img class="box-code-img" src="../../assets/index/st.png" alt=""> -->
              <div class="box-code-image" id="qrcode" ref="qrcode"></div>
              <div>
                <div class="fs-18">{{ $t('index.smxz') }}</div>
                <div class="fs-14">iOS & Android</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'
import {getIconPriceList} from "@/api/deal";
import QRCode from 'qrcodejs2'

export default {
  data() {
    return {
      getData: [],
      roseData: [],
      activeIndex: 1,
      token: '',
      lang:''
    }
  },
  components: {
    footerb,
    heads
  },
  created() {
    this.token = sessionStorage.getItem('token')
    this.lang = sessionStorage.getItem('lang')
  },
  watch: {
    'getData': function () {
      let roseData=[];
      this.getData.forEach((item)=>{
        roseData.push(item)
      })
      roseData.sort((a, b) => {
        return a.change_num*1 < b.change_num*1 ? 1 : -1;
      })
      this.roseData =roseData
    }
  },
  mounted() {
    this.qrcode()
    this.getIconPriceList()
    document.addEventListener("newCoinPrice",(e)=>{
      // console.log(e)
      if(e.detail){
        // console.log(e.detail)
        this.getData=e.detail
      }
    })
  },
  methods: {
    qrcode () {
      let qrcode = new QRCode("qrcode", {
        width: 82,
        height: 82,
        text: "https://app.kxmbuy.com/"
      });
    },
    getIconPriceList() {
      getIconPriceList().then(res => {
        if (res.code === 1) {
          this.getData = res.data
        }
      })
    },
    btn(id) {
      this.activeIndex = id
    },
    toReg(){
       this.$router.push('./reg')
    },
    toDel(item){
      this.$router.push({path:`/transaction?id=${item.id}&coin_name=${item.coin_name}`})
    },
  }
}

</script>
<style scoped>
.box-banner {
  width: 100%;
  height: 630px;
  background: url('../../assets/index/banner.png') no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fs-16{
  font-size: 16px;
}
.fs-18{
    font-size: 18rpx;
    color: #FFFFFF;margin-bottom: 6px;
  }
.fs-14{
  font-size: 14rpx;
  color: #FFFFFF;
}
.box-code-image{
    margin: 28px;
    background-color: #fff;
    padding: 6px;
  }
.box-banner-tit {
  text-align: center;
  font-size: 72px;
  color: #ffffff;
  margin-bottom: 86px;
}

.box-banner-reg {
  width: auto;
  height: 70px;
  border-radius: 10px;
  background: linear-gradient(90deg, #36D8EF 0%, #2FB1F0 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212121;
  font-size: 36px;
  cursor: pointer;
  padding: 0 90px;
}

.quotation-box {
  width: 100%;
  height: auto;
  background: #29343D;
  padding: 28px 0;
}

.quotation-box-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.quotation-box-tit {
  font-size: 16px;
  color: #FFFFFF;
}

.quotation-box-nav {
  display: flex;
  align-items: center;
  padding-bottom: 28px;
}

.quotation-active {
  border-bottom: 1px solid #FFC300;
  color: #FFC300;
}

.quotation-box-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #1F282F;
  margin-bottom: 10px;
  cursor: pointer;
}

.quotation-list {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid #1F282F;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.quotation-list-tit {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 160px;
}

.quotation-list-tit div {
  width: 25%;
  font-size: 14px;
}

.quotation-list-red {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green {
  color: #4DB871;
}
.kuang{
  color:  rgb(255, 107, 101);
}

.intro-flex {
  display: flex;
  justify-content: right;
  align-items: center;
}

.intro {
  width: auto !important;
  height: 26px;
  padding: 0 6px;
  border-radius: 4px;
  background: #FFC300;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #212121;
}

.box-about {
  width: 100%;
  height: auto;
  background: #171E28;
  padding: 110px 0;
}

.box-about-tit {
  font-size: 46px;
  color: #FFFFFF;
  margin: 0 auto;
  margin-bottom: 80px;
  padding-bottom: 6px;
  text-decoration: #19BFF8;
  border-bottom: 1px solid #FFC300;

}

.box-about-img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.box-about-img2 {
  width: 240px;
  height: 240px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.box-about-img3 {
  width: 255px;
  height: 255px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.box-about-flex {
  display: flex;
  justify-content: space-around;
}

.box-about-son {
  width: 16%;
}
.box-android{
  width: 270px;
  height: 88px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(#27323A, #1C242A,#050607);
  border-radius: 8px;
}
.box-android-img{
  width: 40px;
  height: 40px;
  margin-left: 40px;
}
.box-android-text{
  margin-left: 20px;
  font-size: 28px;
  color: #3ABBF8;
}

.box-about-son-tit {
  font-size: 20px;
  margin-bottom: 15px;
  color: #FFFFFF;
}

.box-about-son-tit2 {
  font-size: 36px;
  margin-bottom: 10px;
  color: #FFFFFF;
  text-align: center;
}

.box-about-son-word {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.box-advantage {
  width: 100%;
  padding: 50px 0;
  background: #29343D;
  overflow: hidden;
}

.box-advantage-flex {
  display: flex;
  justify-content: space-around;
}

.box-about-goole {
  width: 270px;
  height: 88px;
}

.box-code {
  min-width: 350px;
  height: auto;
  background: #171E28;
  box-sizing: border-box;
}

.box-code-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  padding: 34px;
}

</style>
