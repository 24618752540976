let Socket = ''
let setIntervalWesocketPush = null
let socketUrl = 'wss://socket.kxmbuy.net'
// let socketUrl = 'ws://8.210.73.128:8081'
/**
 * 建立websocket连接
 * @param {string} url ws地址
 */
export const createSocket = () => {
  Socket && Socket.close()
  if (!Socket) {
    console.log('建立websocket连接')
    Socket = new WebSocket(socketUrl)
    Socket.onopen = onopenWS
    Socket.onmessage = onmessageWS
    Socket.onerror = onerrorWS
    Socket.onclose = oncloseWS
  } else {
    console.log('websocket已连接')
  }
}

/**打开WS之后发送心跳 */
const onopenWS = () => {
  sendPing()
  let token = sessionStorage.getItem('token')
  if (token) sendWSPush({'emit': 'login', 'data': {'token': token}})
  let dealId = sessionStorage.getItem('dealId')
  if (dealId) sendWSPush({'emit': 'setDealId', 'data': {'dealId': dealId}})
}

/**连接失败重连 */
const onerrorWS = () => {
  Socket.close()
  clearInterval(setIntervalWesocketPush)
  console.log('连接失败重连中')
  if (Socket.readyState !== 3) {
    Socket = null
    createSocket()
  }
}

/**WS数据接收统一处理 */
const onmessageWS = e => {
  if (e.data !== 'pong' && e.data !== 'ping') {
    if (e.data) {
      let data = JSON.parse(e.data)
      if (data.code === 0) {
        if (data.emit === 'newCoinDepth') {
          //获取到最新交易对信息
          let newCoinDepth = new CustomEvent("newCoinDepth", {detail: data.data})
          document.dispatchEvent(newCoinDepth)
        } else if (data.emit === 'newCoinPrice') {
          let newCoinPrice = new CustomEvent("newCoinPrice", {detail: data.data})
          document.dispatchEvent(newCoinPrice)
        }
      }
    }
  }else if(e.data!=='pong'){
    Socket.send('ping')
  }

}

/**
 * 发送数据但连接未建立时进行处理等待重发
 * @param {any} message 需要发送的数据
 */
const connecting = message => {
  Socket = null
  createSocket()
}

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
export const sendWSPush = message => {
  if (Socket !== null && Socket.readyState === 3) {
    Socket = null
    createSocket()
  } else if (Socket.readyState === 1) {
    Socket.send(JSON.stringify(message))
  } else if (Socket.readyState === 0) {
    connecting(message)
  }
}

/**断开重连 */
const oncloseWS = () => {
  clearInterval(setIntervalWesocketPush)
  console.log('websocket已断开....正在尝试重连')
  Socket = null
  createSocket()
}
/**发送心跳
 * @param {number} time 心跳间隔毫秒 默认5000
 * @param {string} ping 心跳名称 默认字符串ping
 */
export const sendPing = (time = 2000, ping = 'ping') => {
  clearInterval(setIntervalWesocketPush)
  Socket.send(ping)
  setIntervalWesocketPush = setInterval(() => {
    Socket.send(ping)
  }, time)
}
