<template>
  <div class="content">
    <div class="footer">
      <div class="footer-left">
        <img class="footer-logo" src="../assets/logo.png" alt="" />
        <div class="community">{{ $t('footer.sq') }}</div>
        <div class="footer-img-flex">
          <img class="footer-img" src="../assets/tt.png" alt="" />
          <img class="footer-img" src="../assets/ytb.png" alt="" />
          <img class="footer-img" src="../assets/fb.png" alt="" />
          <img
            class="footer-img"
            style="margin-right: 0"
            src="../assets/in.png"
            alt=""
          />
        </div>
      </div>
      <div class="footer-main">
        <div class="title">{{ $t('footer.zc') }}</div>
        <div>{{ $t('footer.bzzx') }}</div>
        <div>{{ $t('footer.hysm') }}</div>
        <div>{{ $t('footer.gywm') }}</div>
        <div>{{ $t('footer.fvbz') }}</div>
        <div>{{ $t('footer.sbsq') }}</div>
      </div>
      <div class="footer-main2">
        <div class="title">{{ $t('footer.tk') }}</div>
        <div>{{ $t('footer.flsm') }}</div>
        <div>{{ $t('footer.fxts') }}</div>
        <div>{{ $t('footer.yszc') }}</div>
        <div>{{ $t('footer.yhxy') }}</div>
      </div>
      <div class="footer-right">
        <div class="title">{{ $t('footer.lxwm') }}</div>
        <div>{{ $t('footer.swhz') }}：adfiurhwei@ddd.com</div>
        <div>{{ $t('footer.kfyx') }}：adfiurhwei@ddd.com</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String
  }
}
</script>


<style scoped>
/* 底部 */
.footer {
  width: 100%;
  height: auto;
  background: #1f282f;
  /* position: fixed;
    bottom: 0; */
  color: #ffffff;
  padding: 50px 5%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.footer-logo {
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
}
.footer-left {
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-img-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-img {
  width: 24px;
  height: 24px;
}
.community {
  font-size: 14px;
  margin-bottom: 16px;
}
.title {
  font-size: 16px;
  margin-bottom: 20px;
  color: #ffffff !important;
}
.footer-main div {
  margin-bottom: 10px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
.footer-main2 div {
  margin-bottom: 10px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
.footer-right div {
  margin-bottom: 10px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
</style>
