<template>
  <div class="content">
    <div class="nav">
      <img class="nav-logo" src="../assets/logo.png" alt="" />
      <div @click="jump(0)" :class="[activeIndex=='/'?'active':'']">{{ $t('nav.sy') }}</div>
      <div @click="jump(1)" :class="[activeIndex == '/market' ? 'active' : '']">{{ $t('nav.sc') }}</div>
      <div @click="jump(2)" :class="[activeIndex == '/transaction' ? 'active' : '']">{{ $t('nav.bbjy') }}</div>
      <div @click="jump(3)" :class="[activeIndex == '/futures' ? 'active' : '']">{{ $t('nav.qhjy') }}</div>
      <div @click="jump(4)" v-if="token" :class="[activeIndex == '/recharge' ? 'active' : '']">{{ $t('nav.cb') }}</div>
      <div @click="jump(5)" v-if="token" :class="[activeIndex == '/withdraw' ? 'active' : '']">{{ $t('nav.tb') }}</div>
      <div @click="jump(6)" style="margin-right: 0" :class="[activeIndex == '/download' ? 'active' : '']">{{ $t('nav.xz') }}
      </div>
      <div class="nav-right">
        <div @click="jump(7)" v-if="token" :class="[activeIndex == '/assets' ? 'active' : '']">{{ $t('nav.zc') }}</div>
        <div @click="jump(8)" v-if="token" :class="[activeIndex == '/order' ? 'active' : '']">{{ $t('nav.da') }}</div>
        <div @click="jump(9)" v-if="!token" class="nav-login" :class="[activeIndex == '/login' ? 'active' : '']">{{
          $t('nav.dl')
        }}</div>
        <div @click="jump(10)" v-if="!token" class="nav-login" :class="[activeIndex == '/reg' ? 'active' : '']">{{
          $t('nav.reg')
        }}</div>
        <el-tooltip placement="top"  v-if="token">
          <div slot="content" @click="getOut">{{ $t('nav.out') }}</div>
          <div @click.stop="jump(11)">
            <img class="personal" src="../assets/market/people.png" alt="">
          </div>
        </el-tooltip>
        <div class="box-right">
          <el-select v-model="value" :placeholder="value" @change="changeLang($event)">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '/',
      value: 'en',
      options: [{
        value: 'zh-CN',
        label: '中文简体'
      }, {
        value: 'zh-CN2',
        label: '中文繁體'
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'Japanese',
        label: 'にほんご'
      }, {
        value: 'French',
        label: 'Français'
      }, {
        value: 'Deutsch',
        label: 'Deutsch'
      }, {
        value: 'Spanish',
        label: 'Español'
      }
      ],
      token: ''
    }
  },
  created() {
    this.activeIndex = this.$route.path
    let lang = sessionStorage.getItem('lang')
    if(lang){
      this.value = sessionStorage.getItem('lang')
    }else{
      this.value = 'en'
      sessionStorage.setItem('lang', 'en')
    }
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    jump(id) {
      let idIndex = sessionStorage.setItem('id', id)
      this.activeIndex = this.$route.path

      if (id == 0) {
        this.$router.push('/')
      } else if (id == 1) {
        this.$router.push('/market')
      } else if (id == 2) {
        this.$router.push('/transaction')
      } else if (id == 3) {
        this.$router.push('/futures')
      } else if (id == 4) {
        this.$router.push('/recharge')
      } else if (id == 5) {
        this.$router.push('/withdraw')
      } else if (id == 6) {
        this.$router.push('/download')
      } else if (id == 7) {
        this.$router.push('/assets')
      } else if (id == 8) {
        this.$router.push('/order')
      } else if (id == 9) {
        this.$router.push('/login')
      } else if (id == 10) {
        this.$router.push('/reg')
      } else if (id == 11) {
        this.$router.push('/my')
      }

    },
    changeLang(e) {
      sessionStorage.setItem('lang', e)
      this.$i18n.locale = sessionStorage.getItem('lang')
    },
    getOut() {
      this.$router.push('/login')
      sessionStorage.removeItem('token')
    }
  }
}

</script>


<style scoped>
/* 导航栏 */
.nav {
  width: 100%;
  height: 80px;
  background: #1f282f;
  display: flex;
  align-items: center;
  padding: 0 30px;
  position: relative;
  box-sizing: border-box;
}

.nav div {
  color: #ffffff;
  font-size: 16px;
  margin-right: 40px;
  cursor: pointer;
}

.nav-logo {
  width: 40px;
  height: 40px;
  margin-right: 40px;
}

.nav-right {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
}

.nav-login {
  /* width: 64px; */
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  /* border: 1px solid #ffc300; */
  color: #FFB92E !important;
}

.el-button {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  color: #FFC300;
  font-size: 14px !important;
}

.personal {
  width: 32px;
  height: 32px;
}

.box-right {
  margin-right: 0 !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100px;
  padding-left: 10px;
}

/deep/ .el-input__inner {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  color: #FFFFFF;
  line-height: 0;
}

/deep/ .el-select {
  width: 100%;
}
</style>
